import React from "react";

const style = {
  fontWeight: "bold",
  fontSize: "45px",
  lineHeight: "57px",
  color: "#313059",
  paddingBottom: "30px",
  textAlign: "center"
}

export default function SectionTitle( {title, className} ) {
  return(
    <>
      <h2 style={style} className={className}>{title}</h2>
    </>
  );
}
