import React from 'react'
import AuthSideT from './AuthSideT'
import GeneralHeader1 from './GeneralHeader1'
import tutorstyle from './TutorAuth.module.css'
import { Link } from 'react-router-dom'
function AuthSideSub() {
    return (
        <div>
            <GeneralHeader1 />
            <main className={tutorstyle.BigContainer}>
                <div className={tutorstyle.Container}>
                <AuthSideT />
                <section className={tutorstyle.secondSide}>
                    <h4 className={tutorstyle.secondSideH4}>Subject Specialty</h4>
                    <p className={tutorstyle.UpperInstructionP}>
                        <b>FNG</b> give you the opportunity to teach and share your knowledge more student beyond your reach
                    </p>
                    <form action="" className={tutorstyle.form}>
                            <h5 className={`${tutorstyle.formH5} ${tutorstyle.largeH5}`}>Which Subject do you teach?</h5>
                            <div className={tutorstyle.formDiv}>
                                <label htmlFor="" className={tutorstyle.listmargin}>
                                    <input type="text" className={tutorstyle.input} />
                                </label>
                                <ul className={`${tutorstyle.UlListStyle} ${tutorstyle.listmargin}`}>
                                    <li className={tutorstyle.input}>MATHEMATICS</li>
                                </ul>
                                <p className={tutorstyle.listmargin}>Most taught Subject*</p>
                                <ul className={tutorstyle.UlListStyle}>
                                    <li className={`${tutorstyle.input} ${tutorstyle.listmargin} ${tutorstyle.UlLiStyle}`}>Mathematics</li>
                                    <li className={`${tutorstyle.input} ${tutorstyle.listmargin} ${tutorstyle.UlLiStyle}`}>English</li>
                                    <li className={`${tutorstyle.input} ${tutorstyle.listmargin} ${tutorstyle.UlLiStyle}`}>Mathematics</li>
                                    <li className={`${tutorstyle.input} ${tutorstyle.listmargin} ${tutorstyle.UlLiStyle}`}>English</li>
                                    <li className={`${tutorstyle.input} ${tutorstyle.listmargin} ${tutorstyle.UlLiStyle}`}>Mathematics</li>
                                    <li className={`${tutorstyle.input} ${tutorstyle.listmargin} ${tutorstyle.UlLiStyle}`}>English</li>
                                    <li className={`${tutorstyle.input} ${tutorstyle.listmargin} ${tutorstyle.UlLiStyle}`}>Mathematics</li>
                                    <li className={`${tutorstyle.input} ${tutorstyle.listmargin} ${tutorstyle.UlLiStyle}`}>English</li>
                                    <li className={`${tutorstyle.input} ${tutorstyle.listmargin} ${tutorstyle.UlLiStyle}`}>Mathematics</li>
                                </ul>
                        </div>
                        <button className={`${tutorstyle.btn} ${tutorstyle.btnFirstChild}`}>
                            <Link to="/tutor/schedule" style={{color:"#fff", textDecoration:"none"}}>BACK</Link>
                        </button>
                        <button className={`${tutorstyle.btn} ${tutorstyle.btnLastChild}`}>
                            <Link to="/tutor/nda" style={{color:"#fff", textDecoration:"none"}}>NEXT</Link>
                        </button>
                    </form>   
                </section>
                </div>
            </main>
        </div>
    )
}

export default AuthSideSub
