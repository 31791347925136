import React, {useState} from 'react'
import './hamburger.css'
import styles from './auth.module.css'
import { HashLink as Link} from 'react-router-hash-link'
import { Link as PageLink } from 'react-router-dom'
import { stack as Menu } from 'react-burger-menu'
function GeneralHeader1() {
    const [menuOpen, setMenuOpen] = useState(false)
    const handleStateChange = (state) => {
        setMenuOpen(state.isOpen)
    }
      const closeMenu = () => {
        setMenuOpen(false)
      }
      
    return (
        
        <header className={`${styles.flexBoxH} ${styles.JContent2} ${styles.header}`}>
            <div className={styles.Nav}>
                <Link to="/" style={{textDecoration:"none"}}>
                    <div className={styles.headerFig}></div>
                </Link>
                <nav className={`${styles.headerNav}`}>
                    <Menu right isOpen={menuOpen} onStateChange={(state) => handleStateChange(state)}>
                            <PageLink to="/#services" style={{textDecoration:"none", padding:"5px 10px", border:"1px solid #fff", marginBottom:"10px"}} className={styles.headerHam} onClick={closeMenu}>Our Services</PageLink>                    
                            <PageLink to="/tutor/signup" style={{textDecoration:"none", padding:"5px 10px", border:"1px solid #fff", marginBottom:"10px"}} onClick={closeMenu}>Become a Tutor</PageLink>                    
                            <PageLink to="/gallery" style={{textDecoration:"none", padding:"5px 10px", border:"1px solid #fff", marginBottom:"10px"}} onClick={closeMenu}>Gallery</PageLink>                    
                            <PageLink to="/about" style={{textDecoration:"none", padding:"5px 10px", border:"1px solid #fff", marginBottom:"10px"}} onClick={closeMenu}>About Us</PageLink>                    
                            <PageLink to="/blog" style={{textDecoration:"none", padding:"5px 10px", border:"1px solid #fff", marginBottom:"10px"}} onClick={closeMenu}>Blog</PageLink>                    
                    </Menu>

                    <ul className={`${styles.listStyle1} ${styles.listStyleH}`}>                              
                        <li className={`${styles.listStyle2} ${styles.headerU}`}>
                            <Link to="/#services" style={{color:"#313059", textDecoration:"none"}}>Our Services</Link>                    
                        </li>
                        <li className={`${styles.listStyle2} ${styles.headerU}`}>
                            <PageLink to="/tutor/signup" style={{color:"#313059", textDecoration:"none"}}>Become a Tutor</PageLink>                    
                        </li>
                        <li className={`${styles.listStyle2} ${styles.headerU}`}>
                            <PageLink to="/gallery" style={{color:"#313059", textDecoration:"none"}}>Gallery</PageLink>                    
                        </li>
                        <li className={`${styles.listStyle2} ${styles.headerU}`}>
                            <PageLink to="/about" style={{color:"#313059", textDecoration:"none"}}>About Us</PageLink>                    
                        </li>
                        <li className={`${styles.listStyle2} ${styles.headerU}`}>
                            <PageLink to="/blog" style={{color:"#313059", textDecoration:"none"}}>Blog</PageLink>                    
                        </li>
                    </ul>
                    {/* <div> */}
                    <button className={`${styles.headerU} ${styles.headerULastChild}`}>
                        <PageLink to="/tutor/signin" style={{color:"#fff", textDecoration:"none"}}>Login</PageLink>                    
                    </button>
                    {/* </div> */}
                </nav>
            </div>
        </header>
    )
}
export default GeneralHeader1
