import React from 'react';
import GeneralHeader1 from './GeneralHeader1'
import styles from './auth.module.css'
import { Link } from 'react-router-dom'
// import { HashLink as Link} from 'react-router-hash-link'
function AuthSignUPT(props) {
    return (
        <div className={styles.overallAuthT}>
            <GeneralHeader1 />
            <main className={styles.authTutorContainer}>
                <div className={`${styles.flexBoxT} ${styles.wrap1} ${styles.paddingSignUp} ${styles.JContent5} ${styles.authTutorSignUp}`}>
                <section className={styles.authTutorSignUpSides}>
                    <div className={styles.fig2}></div>
                    <div style={{padding:"20px 0px"}}>
                        <h1  className={styles.authTFirstSideText}>Become a Tutor</h1>
                        <p className={styles.authTFirstSideText}>FNG offers qualified tutors who have a proven track records of students making the highest scores in all external exams like SAT, TOEFL, IELTS, YOS, GMAT, GRE etc in the world. vFNG works without cease for all students to live their purpose, to study abroad, work and invest in our partner countries. FNG is built on education empowerment.</p>
                    </div>
                </section>
                <section className={`${styles.authTutorSignUpSides} ${styles.sideShadow}`}>
                    <div className={`${styles.flexBox2} ${styles.authT2ndSideDiv} ${styles.AItem1}`}>
                        <p className={styles.authT2ndSideText}>Create your profile</p>
                        <button className={`${styles.authTMethod} ${styles.authTMethod4}`}>Sign up with Facebook</button>
                        <button className={`${styles.authTMethod} ${styles.authTMethod2}`}>Sign up with Google</button>
                        <button className={`${styles.authTMethod} ${styles.authTMethod3}`}>
                        <Link to="/tutorSignUpForm" style={{color:"#fff", textDecoration:"none"}}>Sign with Email</Link>
                            
                        </button>
                    </div>
                    <form action="" className={`${styles.flexBox2} ${styles.authTForm}`} id="tutorSignUpForm">
                        <div className={`${styles.padding4} ${styles.flexBoxT} ${styles.JContent2} ${styles.authHrDivContainer}`}>
                            <hr className={styles.authHr} /> <span className={styles.authHrText}>or</span> <hr className={styles.authHr} />
                        </div>

                        <label htmlFor=""  className={styles.label}>First Name</label>
                        <input type="text" name="" id="" className={styles.input} />
                        <label htmlFor=""  className={styles.label}>Last Name</label>
                        <input type="text" name="" id="" className={styles.input} />
                        <label htmlFor=""  className={styles.label}>Email</label>
                        <input type="email" name="" id="" className={styles.input} />
                        <label htmlFor=""  className={styles.label}>Password</label>
                        <input type="password" name="" id="" className={styles.input} />
                        <div className={styles.labelBox}>
                            <label htmlFor=""></label>
                            <select name="" id="" className={`${styles.input} ${styles.labelSelect}`}></select>
                        </div>
                        <div className={styles.labelBox}>
                            <p>Do you live in Nigeria?</p>
                            <label htmlFor="" className={styles.labelAside}><input type="radio" name="" id="" className={styles.inputAside} />Yes</label>
                            <label htmlFor="" className={styles.labelAside}><input type="radio" name="" id="" className={styles.inputAside} />No</label>
                        </div>
                        <div className={styles.labelBox}>
                            <label htmlFor=""  className={styles.label}></label>
                            <select name="" id="" className={`${styles.input} ${styles.labelSelect2}`}></select>
                        </div>
                        <div className={styles.labelBox}>
                            <p>Do you live in Nigeria?</p>
                            <label htmlFor="" className={styles.labelAside}><input type="radio" name="" id=""  className={styles.inputAside} />Yes</label>
                            <label htmlFor="" className={styles.labelAside}><input type="radio" name="" id="" className={styles.inputAside} />No</label>
                        </div>

                        <label htmlFor="" className={styles.label}>Address of residence</label>
                        <input type="text" name="" id="" className={styles.input}  />
                        <button className={`${styles.label} ${styles.authTMethod} ${styles.authTMethodSub} ${styles.already} ${styles.already2}`}>
                            <Link to="/tutor/education" style={{color:"#fff", textDecoration:"none", fontWeight:"bold"}}>Sign Up</Link>

                        </button>
                        <div className={`${styles.authTMethodLastDiv}`}>
                            <button className={`${styles.label} ${styles.authTMethod} ${styles.authTMethodSub} ${styles.authTMethodLast1}`}>Facebook</button>
                            <button className={`${styles.label} ${styles.authTMethod} ${styles.authTMethodSub} ${styles.authTMethodLast2}`}>Google</button>
                        </div>
                        <p  className={`${styles.label} ${styles.already} ${styles.formParaSpan}`}>Already have an account? <span className={styles.formPara}>LOGIN</span></p>

                    </form>
                </section>
                </div>
            </main>
        </div>
    )
}

export default AuthSignUPT
