import React from 'react'
import styles from './auth.module.css'
import GeneralHeader1 from './GeneralHeader1';
import { Link } from 'react-router-dom'
function AuthSignUpTEmail(props) {
    return (
        <div className={styles.overallAuthT}>
        <GeneralHeader1 />
        <main className={`${styles.flexBoxT} ${styles.wrap1} ${styles.padding3} ${styles.JContent5} ${styles.authTutorSignUp}`}>
        <section className={`${styles.authTutorSignUpSides} ${styles.sideShadow}`}>
        <form action="" className={`${styles.flexBox2}`}>
        <div className={`${styles.padding4} ${styles.flexBoxT} ${styles.JContent2} ${styles.authHrDivContainer}`}>
            <hr className={styles.authHr} /> <span className={styles.authHrText}>or</span> <hr className={styles.authHr} />
        </div>

        <label htmlFor=""  className={styles.label}>First Name</label>
        <input type="text" name="" id="" className={styles.input} />
        <label htmlFor=""  className={styles.label}>Last Name</label>
        <input type="text" name="" id="" className={styles.input} />
        <label htmlFor=""  className={styles.label}>Email</label>
        <input type="email" name="" id="" className={styles.input} />
        <label htmlFor=""  className={styles.label}>Password</label>
        <input type="password" name="" id="" className={styles.input} />
        <div className={styles.labelBox}>
            <label htmlFor=""  className={styles.label}></label>
            <select name="" id="" className={styles.input}></select>
        </div>
        <div className={styles.labelBox}>
            <p>Do you live in Nigeria?</p>
            <label htmlFor="" className={styles.labelAside}><input type="radio" name="" id="" className={styles.inputAside} />Yes</label>
            <label htmlFor="" className={styles.labelAside}><input type="radio" name="" id="" className={styles.inputAside} />No</label>
        </div>
        <div className={styles.labelBox}>
            <label htmlFor=""  className={styles.label}></label>
            <select name="" id="" className={styles.input}></select>
        </div>
        <div className={styles.labelBox}>
            <p>Do you live in Nigeria?</p>
            <label htmlFor="" className={styles.labelAside}><input type="radio" name="" id=""  className={styles.inputAside} />Yes</label>
            <label htmlFor="" className={styles.labelAside}><input type="radio" name="" id="" className={styles.inputAside} />No</label>
        </div>

        <label htmlFor="" className={styles.label}>Address of residence</label>
        <input type="text" name="" id="" className={styles.input}  />
        <button className={`${styles.label} ${styles.authTMethod} ${styles.authTMethodSub} ${styles.already}`}>
        <Link to="/tutor/education" style={{color:"#fff", textDecoration:"none", fontWeight:"bold"}}>Sign Up</Link>
            
        </button>
        <div className={`${styles.authTMethodLastDiv}`}>
            <button className={`${styles.label} ${styles.authTMethod} ${styles.authTMethodSub} ${styles.authTMethodLast1}`}>Facebook</button>
            <button className={`${styles.label} ${styles.authTMethod} ${styles.authTMethodSub} ${styles.authTMethodLast2}`}>Google</button>
        </div>
        <p  className={`${styles.label} ${styles.already} ${styles.formParaSpan}`}>Already have an account? <span className={styles.formPara}>LOGIN</span></p>

    </form>
    </section>
    </main>
    </div>
    )
}

export default AuthSignUpTEmail
