import React from 'react'
import tutorstyle from './TutorAuth.module.css'

function AuthSideT() {
    return (
        <section className={tutorstyle.firstSide}>
            <h2 className={tutorstyle.firstSideH2}>Welcome Tutor Name</h2>
            <p className={tutorstyle.firstSideInt}>
            Thank you for your interest in becoming a tutor with FNG. You may now proceed with the application process. All information submitted in this application will be channelled via a secure https server. <br />
            </p>
            <p className={tutorstyle.firstSideInt}>
                <strong>Please Note:</strong> <br />
                <span>If you have tutored for an agent in the past or presently tutoring for an agent, we will not accept your application until you submit a consent from them.</span>
            </p>
        </section>
    )
}

export default AuthSideT
