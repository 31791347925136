import React from "react";
import { Button } from "react-bootstrap";

const style = {
  background: "#3C3B6E",
  borderColor: "#3C3B6E",
  borderRadius: "4px",
  fontWeight: "600",
  fontSize: "14px",
  lineHeight: "18px",
  textTransform: "uppercase",
  color: "#FFF",
  padding: "10px 50px"
}

export default function PrimaryButton( {value} ) {
  return(
    <>
      <Button variant="primary" style={style}>{value}</Button>
    </>
  );
}
