import React from 'react'
import searchstyles from './faq.module.css'
import {ReactComponent as FilterImage} from './images/filter.svg';

function SearchFilter(props) {
    return (
        <div className={searchstyles.filterSide1} style={{marginTop:props.space, display: props.toggle ? "flex" : "none"}}>
        <nav className={searchstyles[props.show]}>
            <div className={searchstyles.desktopFilter}>
                <FilterImage />
                <p className={searchstyles.filterUpText}>Filter Services</p>
            </div>
            <div className={searchstyles.checkContainer}>
                <div className={searchstyles.desktopFilter2}>
                    <label htmlFor="">
                        <input type="checkbox" name="" id="" />
                    </label>
                    <p className={searchstyles.checkText}>Examination Preparation and Registration</p>
                </div>
                <div className={searchstyles.desktopFilter2}>
                    <label htmlFor="">
                        <input type="checkbox" name="" id="" />
                    </label>
                    <p className={searchstyles.checkText}>Tourism</p>
                </div>
                <div className={`${searchstyles.desktopFilter2} ${searchstyles.desktopFilterLast}`}>
                    <label htmlFor="">
                        <input type="checkbox" name="" id="" />
                    </label>
                    <p className={searchstyles.checkText}>Study Abroad</p>
                </div>
            </div>
        </nav>
    </div>
)
}

export default SearchFilter
