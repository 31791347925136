import React from 'react'
import AuthSideT from './AuthSideT'
import GeneralHeader1 from './GeneralHeader1'
import tutorstyle from './TutorAuth.module.css'
function AuthSideTech() {
    return (
        <div>
            <GeneralHeader1 />
            <main className={tutorstyle.Container}>
                <AuthSideT />
                <section className={tutorstyle.secondSide}>
                    <h4 className={tutorstyle.secondSideH4}>Technical Specification</h4>
                    <form action="">
                        <div>
                            <h5>Technical Specification</h5>
                            <p>Your computer, or any computer you own and can use for tutoring, must meet the following specification to be accepted as a FNG tutor applicant*</p>
                            <p>If you are unsure of the hardware specification of your computer, click here for instructions on looking up your specs.</p>
                            <p>Do you have a laptop</p>
                            <ul>
                                <li>
                                    <input type="radio" name="" id="" />
                                    <label htmlFor="">Yes</label>
                                </li>
                                <li>
                                    <input type="radio" name="" id="" />
                                    <label htmlFor="">No</label>
                                </li>
                            </ul>
                            <p>Laptop specs</p>
                            <ul>
                                <li>
                                    <input type="radio" name="" id="" />
                                    <label htmlFor="">Mac</label>
                                </li>
                                <li>
                                    <input type="radio" name="" id="" />
                                    <label htmlFor="">Pc</label>
                                </li>
                            </ul>
                            <p>Hardware requirements:<span>*</span></p>
                            <ul>
                                <li>4 GB or more of system memory (RAM)</li>
                                <li>1.8 Ghz multi-core processor (CPU) or faster</li>
                                <li>A monitor or display capable of at least 720 pixels vertically</li>
                            </ul>
                            <p>Does you computer -or any computer own and can use for tutoring - meet the windows requirement?*</p>
                            <ul>
                                <li>
                                    <input type="radio" name="" id="" />
                                    <label htmlFor="">Yes</label>
                                </li>
                                <li>
                                    <input type="radio" name="" id="" />
                                    <label htmlFor="">No</label>
                                </li>
                            </ul>
                            <p>To be able to work online with our student through the classroom, a high-speed internet connection of 15 Mbps down, 5 Mbps up is required. (We recommend 20Mbps down, 10 Mbps up)</p>
                            <ul>Here are some examples of an acceptable connection:
                                <li>Cable, DSL, fiber-optic, T1, T3, Oc3, or any other landbase connection. A home or office Wi-Fi connection linked to a wired router or access point is also OK.</li>
                            </ul>
                            <ul>Here are some examples of an connection that are not compatible with the classroom due to slow speeds and/or connection instability:
                                <li>3G or 4G cellular internet, satellite internet, hospot style public Wi-Fi connection instability </li>
                            </ul>
                            <p>Does you have regular access to a high-speed connection that meets our requirements?:*</p>
                            <ul>
                                <li>
                                    <input type="radio" name="" id="" />
                                    <label htmlFor="">Yes</label>
                                </li>
                                <li>
                                    <input type="radio" name="" id="" />
                                    <label htmlFor="">No</label>
                                </li>
                            </ul>
                            <p>Your must possess certain software programs during the application process and as an active tutor. They are as follows:</p>
                            <ul>
                                <li>Microsoft Office or equivalent 3rd party software than can view and edit Office file (.docx, .xlsx, .pptx) and Pdf reader (Acrobat reader DC or Pro)</li>
                            </ul>
                            <p>Does your tutoring computer meet these software requirement?:*</p>
                            <ul>
                                <li>
                                    <input type="radio" name="" id="" />
                                    <label htmlFor="">Yes</label>
                                </li>
                                <li>
                                    <input type="radio" name="" id="" />
                                    <label htmlFor="">No</label>
                                </li>
                            </ul>
                            <p>FNG requires that all tutor suitable hardware to communicate with students using the classroom’s voice feature. Virtually any Mac or PC suitable headset, or speakers with a standing microphone, will suffice to meet this requirement.</p>
                            <p>Do you possess a headset or speaker/microphone setup suitable for use with the voice feature?:*</p>
                            <ul>
                                <li>
                                    <input type="radio" name="" id="" />
                                    <label htmlFor="">Yes</label>
                                </li>
                                <li>
                                    <input type="radio" name="" id="" />
                                    <label htmlFor="">No, i do not possess a working headset and microphone (or phone) and/or cannot obtain one later on in the application process. (NOTE: if choosing this answer, do not proceed with the remainder of the application.)</label>
                                </li>
                            </ul>
                            <p>Suitable hardware is be needed to communicate with students using the classroom’s video feature. Virtually any Mac or PC suitable webcam or integrated camera, will suffice to meet this requirement.</p>
                            <p>Do you possess a webcam or integrated camera suitable for use with the Video feature?:*</p>
                            <ul>
                                <li>
                                    <input type="radio" name="" id="" />
                                    <label htmlFor="">Yes</label>
                                </li>
                                <li>
                                    <input type="radio" name="" id="" />
                                    <label htmlFor="">No</label>
                                </li>
                            </ul>
                        </div>
                        <button>BACK</button><button>NEXT</button>
                    </form>
                </section>
            </main>
        </div>
    )
}

export default AuthSideTech
