import React from 'react'
import GeneralHeader1 from './GeneralHeader1'
import StudyAbroadHeader from './StudyAbroadHeader'
import studystyle from './Study.module.css'
import StudySide from './StudySide'
import StudyBottom from './StudyBottom'
import Footer from './Footer'
import StudyEI from './StudyEI'

function StudyusaEI() {
    const faint = {
        opacity:0.3,
        backgroundColor:"rgba(0, 0, 0, 0.4)"
    }
    return (
        <div>
            <GeneralHeader1 />
            <StudyAbroadHeader opacity={faint} />
            <main className={studystyle.countryPage} style={faint}>
                <StudySide />
                <section className={studystyle.pageSide2}>
                    <p className={studystyle.pageP}>The United States of America (USA) hosts the most number of international students in the world. <b>Quality education, unique curriculum, multicultural environment</b>, and <b>abundant opportunities</b> are just some of the reasons why many International students want to study in the US.</p>
                    <ul className={studystyle.listContainer}>
                        <h4 className={studystyle.pageSideH4}>Why Choosing to Study in USA?</h4>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Academic excellence</h5>
                            <p className={studystyle.pageP}>
                            The US boasts of some of the finest universities, a lot of which consistently rank in the world university rankings. American institutions are also known to have high academic standards, follow rigorous practices to maintain quality and are well-supported to be able to offer excellent education to its students. As per the QS World Ranking 2019. 33 of the top 100 universities are from the US. Similarly, Times Higher Education Ranking has also ranked seven of the American universities in its top 10 list of universities.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Flexible education system</h5>
                            <p className={studystyle.pageP}>
                            American universities and colleges offer a myriad of courses and programs to choose from. You have the freedom to not only select the course content, but also the structure. At the undergraduate level, you have the liberty to pursue different courses before they you declare your major at the end of the second year. This helps to explore your subject interest and then decide without much hurry. Similarly, for your graduate studies, you can choose your preference and when you progress for your dissertation, you can focus on the ideas you want to emphasise upon.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Excellent support system for international students</h5>
                            <p className={studystyle.pageP}>
                            American universities understand the struggles of international students and therefore conduct regular orientation programs, workshops and trainings to offer assistance. In fact, the international student office helps students like you to get accustomed to a new kind of lifestyle – whether it is an academic query, cultural or social, the staff will be there to assist you round the clock.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Cultural diversity</h5>
                            <p className={studystyle.pageP}>
                            The US is a melting pot of different cultures, races and ethnicities. Its diverse environment ensures that there is acceptance among all communities and there is no room for any sort of discrimination. You’ll be learning with students from different regions of the world thereby making it a rich and stimulating education experience. Growing in the midst of diversity will provide you with strong personality traits and skills that will be valuable in the international market. These days employers prefer students with a multi-cultural background, which you would get a great taste of in the US. A one of a kind international exposure, the US will help you explore variety of cuisines, customs, festivals and art too.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Lively and vibrant campus life</h5>
                            <p className={studystyle.pageP}>
                                It’s a known fact that campus life of the US is matchless. Irrespective of the university you study in, you will find yourself in the midst of new cultural experiences and the American way of life. Embrace it and open yourself up to new ideas and new people.
                            </p>
                        </li>
                    </ul>
                    <StudyBottom />
                </section>
            </main>
            <Footer />
            <StudyEI name="usa" location="/study/abroad/usa/Sum" />
        </div>
    )
}

export default StudyusaEI
