import React, {useState} from 'react'
import styles from './footer.module.css'
import examMiscStyles from './ExamMisc.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom'
import facebook from "../images/facebook.svg";
import instagram from "../images/instagram.svg";
import twitter from "../images/twitter.svg";
import linkedin from "../images/linkedin.svg";
import blog from "../images/blog.svg";

function Footer(props) {
    const [isUpContact, setIsUpContact] = useState(false)
    const [isDownContact, setIsDownContact] = useState(false)
    const [isUpService, setIsUpService] = useState(false)
    const [isDownService, setIsDownService] = useState(false)
    const [isUpCareer, setIsUpCareer] = useState(false)
    const [isDownCareer, setIsDownCareer] = useState(false)
    const [isUpSupport, setIsUpSupport] = useState(false)
    const [isDownSupport, setIsDownSupport] = useState(false)

    const toggleContactClick = () => {
        if (window.innerWidth <= 600){
            setIsUpContact(true)
            setIsDownContact(true)

            setIsUpService(false)
            setIsDownService(false)
            setIsUpCareer(false)
            setIsDownCareer(false)
            setIsUpSupport(false)
            setIsDownSupport(false)
        }
    }
    const toggleServiceClick = () => {
        if (window.innerWidth <= 600){
            setIsUpService(true)
            setIsDownService(true)

            setIsUpContact(false)
            setIsDownContact(false)
            setIsUpCareer(false)
            setIsDownCareer(false)
            setIsUpSupport(false)
            setIsDownSupport(false)
        }
    }
    const toggleCareerClick = () => {
        if (window.innerWidth <= 600){
            setIsUpCareer(true)
            setIsDownCareer(true)

            setIsUpService(false)
            setIsDownService(false)
            setIsUpContact(false)
            setIsDownContact(false)
            setIsUpSupport(false)
            setIsDownSupport(false)
        }
    }
    const toggleSupportClick = () => {
        if (window.innerWidth <= 600){
            setIsUpSupport(true)
            setIsDownSupport(true)

            setIsUpService(false)
            setIsDownService(false)
            setIsUpContact(false)
            setIsDownContact(false)
            setIsUpCareer(false)
            setIsDownCareer(false)
        }
    }

    return (
            <footer className={examMiscStyles[props.bum]}>
            <div className={styles.footer}>
            <nav className={styles.footerNav}>
                <div className={styles.footerNavMainDiv}>
                    <ul>
                        <li className={`${styles.listStyle} ${styles.flex} ${styles.listPhone}`} onClick={toggleContactClick}>
                            <p className={styles.footerP}>Contact Us</p>
                            <FontAwesomeIcon icon={['fas', 'sort-up']} size="1x" className={isUpContact ? styles.up2 : styles.up} />
                            <FontAwesomeIcon icon={['fas', 'sort-down']} size="1x" className={isDownContact ? styles.up : styles.up2} />
                        </li>
                    </ul>
                    <ul className={`${styles.footerListStyle} ${isUpContact ?  styles.footerClick : styles.footerListStyle}`}>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerListPhone}`}>School Address:
                            <p>40a Oduduwa way, GRA Ikeja</p>
                        </li>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerListPhone}`}>Email:
                            <p><a href="mailto:marketing@fngc.com" className={styles.footerList}>marketing@fngc.com</a></p>
                            <p><a href="mailto:info@fngc.com" className={styles.footerList}>info@fngc.com</a></p>
                        </li>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerListPhone}`}>Telephone:
                            <p><a href="tel:+2348012345678" className={styles.footerList}>+234 8012345678</a></p>
                            <p><a href="tel:+2348012345678" className={styles.footerList}>+234 8012345678</a></p>
                        </li>
                    </ul>
                </div> 
                <div className={styles.footerNavMainDiv}>
                    <ul>
                        <li className={`${styles.listStyle} ${styles.flex} ${styles.listPhone}`} onClick={toggleServiceClick}>
                            <p className={styles.footerP}>Services</p>
                            <FontAwesomeIcon icon={['fas', 'sort-up']} size="1x" className={isUpService ? styles.up2 : styles.up} />
                            <FontAwesomeIcon icon={['fas', 'sort-down']} size="1x" className={isDownService ? styles.up : styles.up2} />
                        </li>
                    </ul>
                    <ul className={`${styles.footerListStyle} ${isUpService ?  styles.footerClick : styles.footerListStyle}`}>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerListPhone}`}>
                            <p>
                            <Link to="/exam" style={{color:"rgba(251, 250, 255, 0.8)", textDecoration:"none"}}>Exam Prep</Link>                    
                            </p>
                        </li>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerListPhone}`}>
                            <p>
                            <Link to="/error" style={{color:"rgba(251, 250, 255, 0.8)", textDecoration:"none"}}>Tourism</Link>                    
                            </p>
                        </li>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerListPhone}`}>
                            <Link 
                                to="/study/abroad" 
                                style={{color:"rgba(251, 250, 255, 0.8)", textDecoration:"none"}}
                                
                            >
                                Study Abroad
                            </Link>                    
                        </li>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerListPhone}`}>
                            <p>
                            <Link to="/error" style={{color:"rgba(251, 250, 255, 0.8)", textDecoration:"none"}}>Learn a new language</Link>                    
                            </p>
                        </li>                       
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerListPhone}`}>
                            <Link to="/error" style={{color:"rgba(251, 250, 255, 0.8)", textDecoration:"none"}}>Adult Literacy</Link>                    
                        </li>
                    </ul>
                </div>
                <div className={styles.footerNavMainDiv}>
                    <ul>
                    <li className={`${styles.listStyle} ${styles.flex} ${styles.listPhone}`} onClick={toggleCareerClick}>
                        <p className={styles.footerP}>Career</p>
                        <FontAwesomeIcon icon={['fas', 'sort-up']} size="1x" className={isUpCareer ? styles.up2 : styles.up} />
                        <FontAwesomeIcon icon={['fas', 'sort-down']} size="1x" className={isDownCareer ? styles.up : styles.up2} />
                    </li>
                    </ul>
                    <ul className={`${styles.footerListStyle} ${isUpCareer ?  styles.footerClick : styles.footerListStyle}`}>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerListPhone}`}>
                            <p>
                            <Link to="/tutor/signup" style={{color:"rgba(251, 250, 255, 0.8)", textDecoration:"none"}}>Become a Tutor</Link>                    
                            </p>
                        </li>
                    </ul>
                </div>
                <div className={styles.footerNavMainDiv}>
                <ul>
                    <li className={`${styles.listStyle} ${styles.flex} ${styles.listPhone}`} onClick={toggleSupportClick}>
                        <p className={styles.footerP}>Support</p>
                        <FontAwesomeIcon icon={['fas', 'sort-up']} size="1x" className={isUpSupport ? styles.up2 : styles.up} />
                        <FontAwesomeIcon icon={['fas', 'sort-down']} size="1x" className={isDownSupport ? styles.up : styles.up2} />
                    </li>
                    </ul>
                    <ul className={`${styles.footerListStyle} ${isUpSupport ?  styles.footerClick : styles.footerListStyle}`}>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerListPhone}`}>
                            <p>
                            <Link to="/faq" style={{color:"rgba(251, 250, 255, 0.8)", textDecoration:"none"}}>FAQ</Link>                    
                            </p>
                        </li>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerListPhone}`}>
                            <p>
                            <Link to="/contact" style={{color:"rgba(251, 250, 255, 0.8)", textDecoration:"none"}}>Contact Us</Link>                    
                            </p>
                        </li>
                    </ul>
                </div>
                <div className={styles.footerNavMainDiv}>
                    <ul className={styles.socialNames}>
                        <li className={`${styles.listStyle} ${styles.flex}`}>
                            <p className={styles.footerP}>Community</p>
                        </li>
                    </ul>
                    <ul className={styles.flexCommunityU}>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerCommunity}`}>
                            <a href="http://" className={`${styles.footerList} ${styles.footerA}`}>
                                <img src={facebook} alt="facebook icon" />
                                <span className={styles.socialNames}>Facebook</span>
                            </a>
                        </li>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerCommunity}`}>
                            <a href="http://" className={`${styles.footerList} ${styles.footerA}`}>
                                <img src={instagram} alt="instagram icon" />
                                <span className={styles.socialNames}>Instagram</span>
                            </a>
                        </li>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerCommunity}`}>
                            <a href="http://" className={`${styles.footerList} ${styles.footerA}`}>
                                <img src={twitter} alt="twitter icon" />
                                <span className={styles.socialNames}>Twitter</span>
                            </a>
                        </li>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerCommunity}`}>
                            <a href="http://" className={`${styles.footerList} ${styles.footerA}`}>
                                <img src={linkedin} alt="linkedin icon"/>                                    
                                <span className={styles.socialNames}>LinkedIn</span>
                            </a>
                        </li>
                        <li className={`${styles.footerList} ${styles.footerListSpace} ${styles.footerCommunity}`}>
                            <a href="http://" className={`${styles.footerList} ${styles.footerA}`}>
                                <img src={blog} alt="blog icon" />
                                <span className={styles.socialNames}>Blog</span>
                            </a>
                        </li>
                    </ul>
                </div>    
            </nav>
            </div>
            <p className={styles.footerLastP}>&copy; 2021 My FNGC. All Rights Reserved | A product of FNGC</p>
            </footer>
    )
}

export default Footer
