import React from 'react'
import { Link } from 'react-router-dom'
import errorstyles from './checkout.module.css'
import Footer from './Footer'
import GeneralHeader1 from './GeneralHeader1'
function ErrorPage() {
    return (
        <div>
            <GeneralHeader1 />
        <div className={errorstyles.checkAppContainer}>
            <section className={errorstyles.errorSection}>
                <div className={errorstyles.errorFig}></div>
                <p className={errorstyles.errorP}>You didn’t break the internet, this page is missing</p>
                <div className={errorstyles.errorBtnsDiv}>
                {/* <Link to="/" style={{textDecoration:"none"}} > */}
                    <button className={`${errorstyles.errorBtns} ${errorstyles.errorBtn1}`}>Back</button>
                {/* </Link> */}
                    <Link to="/" style={{textDecoration:"none"}} >
                        <button className={`${errorstyles.errorBtns} ${errorstyles.errorBtn2}`}>Home Page</button>
                    </Link>
                </div>
            </section>
        </div>
        <Footer />
        </div>
    )
}

export default ErrorPage
