import React from 'react'
import styles from './auth.module.css'
import { Link } from 'react-router-dom'
import GeneralHeader1 from './GeneralHeader1'
function AuthSignInS(props) {

    return (
        <div>
            <GeneralHeader1 />
            <div className={styles.centerContainer}>

                <div className={`${styles.flexBox1} ${styles.wrap1} ${styles.container}`}>
                    <figure className={styles.fig1}>
                        <img src="" alt="" />
                    </figure>  
                    <div className={`${styles.authSide} ${styles.padding2}`}>
                        <section  className={`${styles.sideShadow} ${styles.sideShadowM} ${styles.authenticationBox}`}>
                            <div className={styles.fngc}></div>
                            <p className={styles.pError2} style={{display:props.display}}>Incorrect password and email combination. Please click on forgot password below to reset your password</p>
                            <form action="" className={`${styles.authSideForm} ${styles.flexBox2}  ${styles.flexBox1}`}>
                                <p className={styles.formPara}>Hello, <br/> <span className={styles.formParaSpan}>Please login to continue</span></p>
                                <label htmlFor="" className={styles.label}>Email</label>
                                <input type="email" name="" id="" placeholder="Enter your email address" className={styles.input} style={{border:props.border}} />
                                <label htmlFor="" className={styles.label}>Password</label>
                                <input type="password" name="" id=""placeholder="Enter your password" className={styles.input} style={{border:props.border}} />
                                <button className={`${styles.input} ${styles.button}`}>
                                    <Link to="/" style={{color:"#fff", textDecoration:"none"}}>LOGIN</Link>
                                    </button>
                                <p className={`${styles.formParaSpan} ${styles.already}`}>
                                    <Link to="/reset/email" style={{color:"#313059", textDecoration:"none"}}>Forgot password</Link>
                                </p>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthSignInS
