import React from 'react'
import GeneralHeader from './GeneralHeader'
import SDashboardSide from './SDashboardSide'

function SDashboardCurr() {
    return (
        <div>
            <GeneralHeader />
            <main>
            <SDashboardSide />
            <section>
                <h4>Curriculum <span>SEE ALL</span></h4>
                    <table>
                        <th>""</th>
                        <th>Subject</th>
                        <th>Class</th>
                        <th>Class type</th>
                        <th>""</th>
                        <tbody>
                            <tr>""</tr>
                            <tr>Garment Making</tr>
                            <tr>SAT</tr>
                            <tr>online</tr>
                            <tr>""</tr>
                        </tbody>
                    </table>
                </section>
            </main>
        </div>
    )
}

export default SDashboardCurr
