import React from 'react'
import GeneralHeader from './GeneralHeader'
import SDashboardSide from './SDashboardSide'

function SDashboardMessage() {
    return (
        <div>
            <GeneralHeader />
            <main>
                <SDashboardSide />
                <div>
                    <section>
                        <figure>
                            <figcaption></figcaption>
                        </figure>
                        <ul>
                            <li>
                                <figure></figure>
                                <div>
                                    <h4>name</h4>
                                    <p></p>
                                </div>
                            </li>
                        </ul>
                    </section>
                    <section>
                        <ul>
                            <li>
                                <figure></figure>
                                <div>
                                    <h4>name</h4>
                                    <p></p>
                                </div>
                            </li>
                        </ul>
                        <div>
                            <ul>received</ul>
                            <ul>sent</ul>
                            <ul>
                                <li><input type="file" name="" id="" /></li>
                                <li><input type="text" name="" id="" /></li>
                                <li><p>send icon</p></li>
                            </ul>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    )
}

export default SDashboardMessage
