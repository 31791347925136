import React, {useState} from 'react'
import Footer from './Footer'
import GeneralHeader1 from './GeneralHeader1'
import reviewstyles from './SearchReview.module.css'
import {ReactComponent as GenImage} from './images/genimage.svg';
import Review from './Review';
import './rating.css'
import {ReactComponent as Arrow} from './images/arrows.svg'

function Testimonials() {
    const [showTestimony, setShowTestimony] = useState(true)
    const [showReview, setShowReview] = useState(true)

    const handleTestimonyClick = () => {
        setShowTestimony(false)
        setShowReview(true)
    }
    const handleReviewClick = () => {
        setShowTestimony(true)
        setShowReview(false)
    }

    const [onTesty, setOnTesty] = useState(true)
    const [onRev, setOnRev] = useState(true)
    const handleTestyHover = () => {
        setOnTesty(false)
        setOnRev(true)
    }
    const handleTestyHoverOut = () => {
        setOnTesty(true)
        setOnRev(true)
    }
    const handleRevHover = () => {
        setOnTesty(true)
        setOnRev(false)
    }
    const handleRevHoverOut = () => {
        setOnTesty(true)
        setOnRev(true)
    }

    return (
        <div>
            <GeneralHeader1 />
            <section className={reviewstyles.firstSection}>
                <div className={reviewstyles.reviewImage}></div>
            </section>
            <div className={reviewstyles.reviewTopic}>
                <div className={reviewstyles.reviewTopicDiv}>
                    <p>What Our Students Say</p>
                    <p>Your Voice Counts</p>
                </div>
            </div>
            <main className={reviewstyles.reviewMain}>
                <div className={reviewstyles.reviewMainDiv}>
                    <section className={reviewstyles.widthDiv}>
                        <div className={reviewstyles.widthDiv2}>
                            <h5 className={reviewstyles.widthH5}>Categories</h5>
                            <p className={`${reviewstyles.widthP1} dopArrow`} onClick={handleTestimonyClick} onMouseOver={handleTestyHover}onMouseOut={handleTestyHoverOut}>Testimonies <span className={onTesty ? "spanArrow" : "spanArrowDisplay"}><Arrow /></span></p>
                            <p className={`${reviewstyles.widthP1} dopArrow`} onMouseOver={handleRevHover} onMouseOut={handleRevHoverOut}>Reviews <span className={onRev ? "spanArrow" : "spanArrowDisplay"}><Arrow /></span></p>
                            <button className={reviewstyles.widthBtn} onClick={handleReviewClick} style={{cursor:"pointer"}}>Tell A Story</button>
                        </div>
                    </section>
                    <section className={reviewstyles.secondDiv}>
                        <section className={showTestimony ? 'rateNone' : 'rateBlock'}>
                        <div className={reviewstyles.reviewTest}>
                            <div className={reviewstyles.imageContainer}>
                                <GenImage />
                                <h5 style={{color:"#313059", textAlign:"center", marginTop:"20px"}}>Sarah Nzube</h5>
                                <p style={{color:"#313059", textAlign:"center"}}>Student</p>
                            </div>
                            <p className={reviewstyles.reviewP}>
                            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nosAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nosAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nos
                            </p>
                        </div>
                        <div className={reviewstyles.reviewTest}>
                            <div className={reviewstyles.imageContainer}>
                                <GenImage />
                                <h5 style={{color:"#313059", textAlign:"center", marginTop:"20px"}}>Sarah Nzube</h5>
                                <p style={{color:"#313059", textAlign:"center"}}>Student</p>
                            </div>
                            <p className={reviewstyles.reviewP}>
                            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nosAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nosAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nos
                            </p>
                        </div>
                        <div className={reviewstyles.reviewTest}>
                            <div className={reviewstyles.imageContainer}>
                                <GenImage />
                                <h5 style={{color:"#313059", textAlign:"center", marginTop:"20px"}}>Sarah Nzube</h5>
                                <p style={{color:"#313059", textAlign:"center"}}>Student</p>
                            </div>
                            <p className={reviewstyles.reviewP}>
                            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nosAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nosAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nos
                            </p>
                        </div>
                        <div className={reviewstyles.reviewTest}>
                            <div className={reviewstyles.imageContainer}>
                                <GenImage />
                                <h5 style={{color:"#313059", textAlign:"center", marginTop:"20px"}}>Sarah Nzube</h5>
                                <p style={{color:"#313059", textAlign:"center"}}>Student</p>
                            </div>
                            <p className={reviewstyles.reviewP}>
                            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nosAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nosAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nos
                            </p>
                        </div>
                        </section>
                        <Review rev={showReview} />
                    </section>
                </div>
            </main>

            <Footer bum="fix" />
        </div>
    )
}

export default Testimonials
