import React from 'react'
import StudyQueHeader from './StudyQueHeader'
import studystyle from './StudyMisc.module.css'
import { Link } from 'react-router-dom'

function StudyEI(props) {
    const colorChange = {
        color: "#3C3B6E",
        padding: "8px 4px",
        borderBottom: "3px solid #3C3B6E",
        fontWeight: "bold"
    }
    return (
        <div className={studystyle.formContainer}>
            <StudyQueHeader EI={colorChange} />
            <form action="" className={studystyle.form}>
                <div className={studystyle.inputBoxGroup}>
                    <div className={studystyle.genWidth}>
                        <p className={studystyle.pModel}>Degree Level</p>
                        <select name={`${props.name}EduLevel`} id={`${props.name}EduLevel`} className={studystyle.inputBox}>
                            <option value="">Choose educational level</option>
                        </select>
                    </div>
                    <div className={studystyle.genWidth}>
                        <p className={studystyle.pModel}>Degree Programme</p>
                        <select name={`${props.name}Programme`} id={`${props.name}Programme`} className={studystyle.inputBox}>
                            <option value="">Choose Programme</option>
                        </select>
                    </div>
                    <div className={studystyle.genWidth}>
                        <p className={studystyle.pModel}>Financial Status</p>
                        <select name={`${props.name}FinancialStatus`} id={`${props.name}FinancialStatus`} className={studystyle.inputBox}>
                            <option value="">Choose financial status</option>
                        </select>
                    </div>
                </div>
                <div className={studystyle.inputBoxGroup}>
                    <div className={studystyle.labelHalf2}>
                        <p className={studystyle.pModel}>Intended Enrollment Time</p>
                        <select name={`${props.name}EnrollmentTerm`} id={`${props.name}EnrollmentTerm`} className={studystyle.inputBox}>
                            <option value="">Choose enrollment term</option>
                        </select>
                    </div>
                    <div className={studystyle.labelHalf3}>
                        <p className={studystyle.pModel}>Intended Enrollment Year</p>
                        <select name={`${props.name}EnrollmentYear`} id={`${props.name}EnrollmentYear`} className={studystyle.inputBox}>
                            <option value="">Choose year</option>
                        </select>
                    </div>
                </div>
                <div className={studystyle.radBtnGroup}>
                    <p className={studystyle.pModel}>The model</p>
                    <label htmlFor="" className={studystyle.radLabel2}>
                        <input type="radio" name={`${props.name}FullTime`} id={`${props.name}FullTime`} className={studystyle.radBtn} />
                        Full time
                    </label>
                    <label htmlFor="" className={studystyle.radLabel2}>
                        <input type="radio" name={`${props.name}PartTime`} id={`${props.name}PartTime`} className={studystyle.radBtn} />
                        Part time
                    </label>
                    <label htmlFor="" className={studystyle.radLabel2}>
                        <input type="radio" name={`${props.name}Extended`} id={`${props.name}Extended`} className={studystyle.radBtn} />
                        Extended
                    </label>
                </div>
                <div className={studystyle.agree}>
                    <label htmlFor="" className={studystyle.radLabel2}>
                        <input type="checkbox" name={`${props.name}Agree`} id={`${props.name}Agree`} className={studystyle.checkBtn} />
                        I agree to receive updates by email from FNG about events, research, and study programmes in line with the FNG privacy policy
                    </label>
                </div>
                <button className={studystyle.btn}>
                <Link to={props.location} style={{color:"#fff", textDecoration:"none"}}>NEXT</Link>                    
                </button>
            </form>
        </div>
    )
}

export default StudyEI
