import React from 'react'
import styles from './footer.module.css'
function ProgressReal() {
    return (
        <div className={styles.realProgressDiv}>
            <h1 className={styles.progressLastH1}>PROGRESS</h1>
        </div>
        )
}

export default ProgressReal
