import React from 'react'
import AuthSignInS from './AuthSignInS'

function AuthSignInT() {
    return (
        <div>
            <AuthSignInS backgroundColor1="#fff" color1="#3C3B6E" backgroundColor2="#3C3B6E" color2="#fff" />
        </div>
    )
}

export default AuthSignInT
