import React from 'react'
import GeneralHeader1 from './GeneralHeader1'
import styles from './AboutHome.module.css'
import Clients from './Clients'
import Guaranteed from './Guaranteed'
import Progress from './Progress'
import ProgressReal from './ProgressReal'
import Enquiry from './Enquiry'
import Footer from './Footer'
import authstyles from './auth.module.css';
import { Link } from 'react-router-dom';
import { ReactVideo } from "reactjs-media";
import FounderVideo from './images/Tosin/fngfounder.mp4'
import Fng90Sec from './images/Tosin/fngsec.mp4'
import PosterImage from './images/fnglogo.svg'
function GalleryPlay(props) {
    return (
        <div>
            <GeneralHeader1 />
            <div style={{paddingTop:"20px", backgroundColor:"#3C3B6E"}}>
            <section className={`${styles.firstSection} ${styles.galleryContainer}`}>
                <div className={`${styles.firstSectionDiv} ${styles.galleryImage} ${styles.gallerySectionDiv}`}>
                    <h1 className={`${styles.firstSectionDivP} ${styles.GalleryCompH1}`}>
                        GALLERY
                    </h1>
                </div>            </section>
            </div>
            <main>
                <section>
                    <div className={styles.topButtonContainer}>
                        <section className={authstyles.buttonSectionGallery}>
                            <button style={{backgroundColor:props.backgroundColor1,color:props.color1}} className={authstyles.topButtons}>
                                <Link to="/gallery" style={{color:props.color1, textDecoration:"none"}}>Pictures</Link>                    
                            </button>
                            <button style={{backgroundColor:props.backgroundColor2,color:props.color2}}className={authstyles.topButtons}>
                                <Link to="/gallery/video" style={{color:props.color2, textDecoration:"none"}}>Videos</Link>                    
                            </button>
                        </section>
                    </div>
                    <div className={styles.galContainer}>
                    <ul className={styles.shortGallery}>
                        <li className={styles.videoLi}>
                        <ReactVideo
                            src={FounderVideo}
                            poster={PosterImage}
                            primaryColor="green"
                            // other props
                        />
                        </li>
                        <li className={styles.videoLi}>
                            <ReactVideo
                                src={Fng90Sec}
                                poster={PosterImage}
                                primaryColor="green"
                                // other props
                            />
                        </li>
                    </ul>
                </div>
                </section>
                <Clients />
            </main>
            <Guaranteed />
            <Progress />
            <ProgressReal />
            <Enquiry />
            <Footer />
        </div>
    )
}

export default GalleryPlay
