import React from "react";
import { Container, Row, Col, InputGroup, FormControl, Button } from "react-bootstrap";
import "./HeroSection.css";
import homepage from "../../images/Homepage.svg";
import searchGray from "../../../images/search-gray.svg";
import searchWhite from "../../../images/search-white.svg";

export default function HeroSection() {
  return(
    <>
      <section className="hero-section">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div>
                <h1>Access to <span>Limitless Learning</span> & <span>Possibilities</span></h1>
                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    <img src={searchGray} alt="search icon" />
                  </InputGroup.Text>
                  <FormControl
                    placeholder="search"
                    aria-label="search"
                    aria-describedby="basic-addon2"
                  />
                  <Button variant="outline-secondary" id="button-addon2">
                    <img src={searchWhite} alt="search icon" />
                  </Button>
                </InputGroup>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <img src={homepage} className="img-fluid" alt="hero" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
