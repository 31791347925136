// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faSortUp } from '@fortawesome/free-solid-svg-icons'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'

library.add(
  faBars,
  faCaretDown,
  faSortUp,
  faSortDown
);