import React from 'react'
import AuthSideT from './AuthSideT'
import AuthSideTEduWorkForm from './AuthSideTEduWorkForm'
import GeneralHeader1 from './GeneralHeader1'
import tutorstyle from './TutorAuth.module.css'
import { Link } from 'react-router-dom'
function AuthSideTEduWork() {
    return (
        <div>
            <GeneralHeader1 />
            <main className={tutorstyle.BigContainer}>
                <div className={tutorstyle.Container}>
                <AuthSideT />
                <section className={tutorstyle.secondSide}>
                    <h4 className={tutorstyle.secondSideH4}>Work Experience</h4>
                    <form action="" className={tutorstyle.form}>
                        <p className={`${tutorstyle.formP} ${tutorstyle.formList}`}>Are you a certified teacher</p>
                        <div className={tutorstyle.formP}>
                            <label htmlFor="" className={tutorstyle.labelBtn2}>
                                <input type="radio" name="" id="" className={tutorstyle.radBtn2} /> Yes
                            </label>
                            <label htmlFor="" className={tutorstyle.labelBtn2}>
                                <input type="radio" name="" id="" className={tutorstyle.radBtn2} /> No
                            </label>
                        </div>
                        <p className={`${tutorstyle.formP} ${tutorstyle.formList} ${tutorstyle.listmargin}`}>Are you a certified teacher</p>

                        <div className={tutorstyle.formP}>
                            <label htmlFor="" className={tutorstyle.labelBtn2}>
                                <input type="radio" name="" id="" className={tutorstyle.radBtn2} /> Yes
                            </label>
                            <label htmlFor="" className={tutorstyle.labelBtn2}>
                                <input type="radio" name="" id="" className={tutorstyle.radBtn2} /> No
                            </label>
                        </div>
                        <select name="" id="" className={`${tutorstyle.input} ${tutorstyle.listmargin}`}>Country 
                            <option value=""></option>
                        </select>
                        <p className={`${tutorstyle.formP} ${tutorstyle.formList} ${tutorstyle.listmargin}`}>Upload Certificate</p>
                        <div className={tutorstyle.fileBox}>
                            <input type="file" name="" id="file" className={`${tutorstyle.files}`} style={{paddingTop:"3px", visibility:'hidden'}} placeholder="cert pdf" />
                            <label htmlFor="file" className={tutorstyle.fileLabel}>Choose file</label>
                        </div>
                        <p className={`${tutorstyle.formP} ${tutorstyle.formTextStyle}`} style={{paddingTop:"3px"}}>We accept pdf files only. <b>Max</b> 20kb</p>
                        <div className={tutorstyle.listmargin}>
                            <h5 className={tutorstyle.formH5}>Employee 1</h5>
                            <AuthSideTEduWorkForm border="1px solid #3C3B6E" padding="30px 22px" />
                        </div>
                        <div>
                            <h5 className={tutorstyle.formH5}>Employee 2</h5>
                            <AuthSideTEduWorkForm border="1px solid #3C3B6E" padding="30px 22px" />
                        </div>
                        <div>
                            <h5 className={tutorstyle.formH5}>Employee3</h5>
                            <AuthSideTEduWorkForm border="1px solid #3C3B6E" padding="30px 22px" />
                        </div>
                        <div>
                            <h5 className={tutorstyle.formH5}>Employee 4</h5>
                            <AuthSideTEduWorkForm border="1px solid #3C3B6E" padding="30px 22px" />
                        </div>
                        <button className={`${tutorstyle.btn} ${tutorstyle.btnFirstChild}`}>
                            <Link to="/tutor/education" style={{color:"#fff", textDecoration:"none"}}>BACK</Link>
                        </button>
                        <button className={`${tutorstyle.btn} ${tutorstyle.btnLastChild}`}>
                            <Link to="/tutor/schedule" style={{color:"#fff", textDecoration:"none"}}>NEXT</Link>
                        </button>
                    </form>
                </section>
                </div>
            </main>
        </div>
    )
}

export default AuthSideTEduWork
