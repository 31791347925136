import React from 'react'
import tutorformstyle from './TutorAuth.module.css'
function AuthSideTEduForm(props) {
    return (
        <div className={tutorformstyle.formCom} style={{border:props.border, padding:props.padding}}>
            <select name="" id="" className={tutorformstyle.input}>
                <option value="">select*</option>
            </select>
            <div className={tutorformstyle.inputDiv}>
                <label htmlFor="">College</label>
                <input type="text" placeholder="Oxford University" className={tutorformstyle.input} />
            </div>
            <select name="" id="" className={tutorformstyle.input}>
                <option value="">Major</option>
            </select>
            <select name="" id="" className={tutorformstyle.input}>
                <option value="">Year attained</option>
            </select>
        </div>
    )
}

export default AuthSideTEduForm
