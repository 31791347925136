import React from 'react'
import Footer from './Footer'
import GeneralHeader from './GeneralHeader'
import SDashboardSide from './SDashboardSide'

function SDashboard() {
    return (
        <div>
            <GeneralHeader />
            <main>
                <SDashboardSide />
                <div>
                    <section>
                        <h4>Curriculum <span>SEE ALL</span></h4>
                        <table>
                            <th>""</th>
                            <th>Subject</th>
                            <th>Class</th>
                            <th>Class type</th>
                            <th>""</th>
                            <tbody>
                                <tr>""</tr>
                                <tr>Garment Making</tr>
                                <tr>SAT</tr>
                                <tr>online</tr>
                                <tr>""</tr>
                            </tbody>
                        </table>
                    </section>
                    <section>
                        <div>
                            <h4>Messages <span>SEE ALL</span></h4>
                            <div>
                                <figure></figure>
                                <div>
                                    <p>Faruk Ayodele</p>
                                    <p>messages</p>
                                </div>
                                <p>Reply</p>
                            </div>
                        </div>
                        <div>
                            <h4>Messages <button>SEE ALL</button></h4>
                            <div>
                                <figure></figure>
                                    <p>Faruk Ayodele</p>
                            </div>
                        </div>
                    </section>
                </div>
                <section>
                <h4>Curriculum <span>SEE ALL</span></h4>
                    <table>
                        <th>""</th>
                        <th>Subject</th>
                        <th>Class</th>
                        <th>Class type</th>
                        <th>""</th>
                        <tbody>
                            <tr>""</tr>
                            <tr>Garment Making</tr>
                            <tr>SAT</tr>
                            <tr>online</tr>
                            <tr>""</tr>
                        </tbody>
                    </table>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default SDashboard
