import React from 'react'
import StudyQueHeader from './StudyQueHeader'
import studystyle from './StudyMisc.module.css'
import { Link } from 'react-router-dom'
// import { faWindows } from '@fortawesome/free-brands-svg-icons'

function StudyEB(props) {
    const colorChange = {
        color: "#3C3B6E",
        padding: "8px 4px",
        borderBottom: "3px solid #3C3B6E",
        fontWeight: "bold"
    }
    
    return (
        <div className={studystyle.formContainer} id={props.eb}>
            <StudyQueHeader EB={colorChange} />
            <form action="" className={studystyle.form}>
                <div className={studystyle.radBtnGroup}>
                    <p>Do you have a High School Degree?</p>
                    <label htmlFor="" className={studystyle.radLabel}>
                        <input type="radio" name={`${props.name}HSDYes`} id={`${props.name}HSDYes`} value="Yes" className={studystyle.radBtn} />
                        Yes
                    </label>
                    <label htmlFor="" className={studystyle.radLabel}>
                        <input type="radio" name={`${props.name}HSDNo`} id={`${props.name}HSDNo`} value="No" className={studystyle.radBtn} />
                        No
                    </label>
                    <label htmlFor="" className={studystyle.radLabel}>
                        <input type="radio" name={`${props.name}HSDOther`} id={`${props.name}HSDOther`} value="Other" className={studystyle.radBtn} />
                        Other
                    </label>
                </div>
                <div className={studystyle.inputBoxGroup}>
                    <p>Current Educational Level</p>
                    <select name={`${props.name}EducationalLevel`} id={`${props.name}EducationalLevel`} className={studystyle.inputBox}>
                        <option value="">Choose educational level</option>
                    </select>
                </div>
                <div className={studystyle.radBtnGroup}>
                    <p>Do you have a High School Degree?</p>
                    <label htmlFor="" className={studystyle.radLabel}>
                        <input type="radio" name={`${props.name}HSDYes2`} id={`${props.name}HSDYes1`} value="Yes" className={studystyle.radBtn} />
                        Yes
                    </label>
                    <label htmlFor="" className={studystyle.radLabel}>
                        <input type="radio" name={`${props.name}HSDNo2`} id={`${props.name}HSDNo2`} value="Yes" className={studystyle.radBtn} />
                        No
                    </label>
                    <label htmlFor="" className={studystyle.radLabel}>
                        <input type="radio" name={`${props.name}HSDOther`} id={`${props.name}HSDOther2`} value="Other" className={studystyle.radBtn} />
                        Other
                    </label>
                </div>
                <div className={studystyle.inputBoxGroup}>
                    <label htmlFor=""></label>
                    <input type="text" name={`${props.name}HSName`} id={`${props.name}HSName`} placeholder="High School Name" className={studystyle.inputBox} />
                </div>
                <div className={studystyle.radBtnGroup}>
                    <p>Do you have a High School Degree?</p>
                    <label htmlFor="" className={studystyle.radLabel}>
                        <input type="radio" name={`${props.name}HSDYes3`} id={`${props.name}HSDYes3`} value="Yes" className={studystyle.radBtn} />
                        Yes
                    </label>
                    <label htmlFor="" className={studystyle.radLabel}>
                        <input type="radio" name={`${props.name}HSDNo3`} id={`${props.name}HSDNo3`} value="No" className={studystyle.radBtn} />
                        No
                    </label>
                    <label htmlFor="" className={studystyle.radLabel}>
                        <input type="radio" name={`${props.name}HSDOther3`} id={`${props.name}HSDOther3`} className={studystyle.radBtn} />
                        Other
                        <input type="text" name={`${props.name}HSDAns`} id={`${props.name}HSDAns`} placeholder="Specify" className={studystyle.inputExtension} />
                    </label>
                </div>
                <div className={studystyle.radBtnGroup}>
                    <p>Do you have a High School Degree?</p>
                    <label htmlFor="" className={studystyle.radLabel}>
                        <input type="radio" name={`${props.name}HSDYes4`} id={`${props.name}HSDYes4`} className={studystyle.radBtn} />
                        Yes
                    </label>
                    <label htmlFor="" className={studystyle.radLabel}>
                        <input type="radio" name={`${props.name}HSDNo4`} id={`${props.name}HSDNo4`} className={studystyle.radBtn} />
                        No
                    </label>
                    <label htmlFor="" className={studystyle.radLabel}>
                        <input type="radio" name={`${props.name}HSDOther`} id={`${props.name}HSDOther`} className={studystyle.radBtn} />
                        Other
                    </label>
                </div>
                <div className={studystyle.inputBoxGroup}>
                    <label htmlFor=""></label>
                    <input type="text" name={`${props.name}CollegeName`} id={`${props.name}CollegeName`} placeholder="College Name*" className={studystyle.inputBox} />
                    <label htmlFor=""></label>
                    <input type="text" name={`${props.name}Course`} id={`${props.name}Course`} placeholder="Course" className={studystyle.inputBox} />
                    <label htmlFor=""></label>
                    <input type="text" name={`${props.name}Degree`} id={`${props.name}Degree`} placeholder="Degree" className={studystyle.inputBox} />
                </div>
                <div className={studystyle.inputBoxGroup2}>
                    <label htmlFor="" className={studystyle.labelHalf}>
                        <input type="text" name={`${props.name}YOA`} id={`${props.name}YOA`} placeholder="Year of Admission" className={studystyle.inputBox} />
                    </label>
                    <label htmlFor="" className={studystyle.labelHalf}>
                        <input type="text" name={`${props.name}GradYear`} id={`${props.name}GradYear`} placeholder="Graduation Year*" className={studystyle.inputBox} />
                    </label>
                </div>
                <div className={studystyle.inputBoxGroup}>
                    <p>Which of these listed test have you taken? <span>Check as appropriate</span></p>
                    <select name={`${props.name}TakenTest`} id={`${props.name}TakenTest`} className={studystyle.inputBox}>
                        <option value="">Choose Test</option>
                    </select>
                </div>
                <button className={studystyle.btn}>
                    <Link to={props.location} style={{color:"#fff", textDecoration:"none"}}>NEXT</Link>                    
                </button>
            </form>
        </div>
    )
}

export default StudyEB
