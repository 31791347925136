import React from 'react'
import Footer from './Footer'
import examstyle from './Exam.module.css'
import authstyle from './authMisc.module.css'
import miscstyle from './ExamMisc.module.css'
import GeneralHeader1 from './GeneralHeader1'
function ExamCheckOutSuccessful() {
    return (
        <div>
            <GeneralHeader1 />
            <main className={`${miscstyle.checkOutContainer} ${examstyle.mainExamContainer}`}>
                <section className={`${examstyle.examWidth} ${miscstyle.checkOut}`}>
                    <div className={authstyle.imageBackground2}></div>
                    <h2 className={miscstyle.checkWidth}>Succesful!</h2>
                    <p className={miscstyle.checkWidth2}>Congratulations</p>
                    <div className={miscstyle.btnDiv}>
                        <button className={`${miscstyle.btn} ${miscstyle.btn1}`}>CONTINUE TO CLASS</button>
                        <button className={`${miscstyle.btn} ${miscstyle.btn2}`}>BACK TO HOME</button>
                    </div>
                </section>
            </main>
            <Footer bum="footerDown" />
        </div>
    )
}

export default ExamCheckOutSuccessful
