import React from 'react'
import tutorformstyle from './TutorAuth.module.css'

function AuthSideTEduWorkForm(props) {
    return (
        <div className={tutorformstyle.formCom} style={{border:props.border, padding:props.padding}}>
            <p className={`${tutorformstyle.formP} ${tutorformstyle.formList}`}>If you do not have any work experience, fill in the field with n/a:<b>*</b></p>
            <div className={tutorformstyle.inputDiv}>
                <label htmlFor="" className={tutorformstyle.formList}>Current or Previous Employer<b>*</b></label>
                <input type="text" name="" id="" placeholder="Current or Previous Employer*" className={tutorformstyle.input} />
            </div>
            <div className={tutorformstyle.inputDiv}>
                <label htmlFor="" className={tutorformstyle.formList}>Job Title<span>*</span></label>
                <input type="text" name="" id="" placeholder="Job Title*" className={tutorformstyle.input} />
            </div>
            <select name="" id="" className={tutorformstyle.input}>Time Agreement*</select>
            <div className={tutorformstyle.inputDiv}>
                <label htmlFor="" className={tutorformstyle.formList}>Date of employment<span>*</span></label>
                <input type="text" name="" id="" placeholder="Date of employment*" className={tutorformstyle.input} />
            </div>
            <div className={tutorformstyle.inputDiv}>
                <label htmlFor="" className={tutorformstyle.formList}>Job Industry<span>*</span></label>
                <input type="text" name="" id="" placeholder="Job Industry*" className={tutorformstyle.input} />
            </div>
        </div>
    )
}

export default AuthSideTEduWorkForm
