import React from 'react'
import pricestyle from './Exam.module.css'
import tutorstyle from './TutorAuth.module.css'
function ExamPrice() {
    return (
        <div className={pricestyle.priceContainer}>
            <main className={pricestyle.mainPrice}>
                <div className={`${pricestyle.divPrice} ${pricestyle.divPriceBorder}`}>
                    <section>
                        <h5 className={`${pricestyle.mainListLiH5} ${pricestyle.priceH5}`}>Examination Profile</h5>
                        <ul className={pricestyle.examTypeContainer}>
                            <li className={pricestyle.examType}>
                                TOEFL
                            </li>
                            <li className={pricestyle.examType}>
                                IELTS
                            </li>
                            <li className={pricestyle.examType}>
                                SAT
                            </li>
                            <li className={pricestyle.examType}>
                                GRE
                            </li>
                            <li className={pricestyle.examType}>
                                GMAT
                            </li>
                            <li className={pricestyle.examType}>
                                YOS
                            </li>
                        </ul>
                    </section>
                    <section>
                        <h5 className={`${pricestyle.mainListLiH5} ${pricestyle.priceH5}`}>Class Type</h5>
                        <ul className={pricestyle.examTypeContainer}>
                            <li className={pricestyle.classType}>
                                Physical Class
                            </li>
                            <li className={pricestyle.classType}>
                                Online Class
                            </li>
                        </ul>
                    </section>
                    <section>
                        <h5 className={`${pricestyle.mainListLiH5} ${pricestyle.priceH5}`}>Training Type</h5>
                        <ul className={pricestyle.examTypeContainer}>
                            <li className={pricestyle.classType}>
                                Regular Course
                            </li>
                            <li className={pricestyle.classType}>
                                Crash Course
                            </li>
                        </ul>
                    </section>
                    <section style={{overflowX:"auto"}}>
                        <table className={pricestyle.table}>
                            <th className={pricestyle.th}>TOEFL</th>
                            <th className={pricestyle.th}>Price</th>
                            <tbody>
                                <tr>
                                    <td className={pricestyle.td}>1 Month</td>
                                    <td className={pricestyle.td}>N 80,000</td>
                                </tr>
                                <tr>
                                    <td className={pricestyle.td}>2 Month</td>
                                    <td className={pricestyle.td}>N 120,000</td>
                                </tr>
                                <tr>
                                    <td className={pricestyle.td}>3 Month</td>
                                    <td className={pricestyle.td}>N 150,000</td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <ul className={`${pricestyle.examTypeContainer} ${pricestyle.marginTop}`}>
                            <li className={pricestyle.classType}>
                                Regular Course
                            </li>
                            <li className={pricestyle.classType}>
                                Crash Course
                            </li>
                    </ul>
                    <section style={{overflowX:"auto"}}>
                        <table className={pricestyle.table}>
                            <th className={pricestyle.th}>TOEFL</th>
                            <th className={pricestyle.th}>Price</th>
                            <tbody>
                                <tr>
                                    <td className={pricestyle.td}>1 Day</td>
                                    <td className={pricestyle.td}>N 15,000</td>
                                </tr>
                                <tr>
                                    <td className={pricestyle.td}>1 Week</td>
                                    <td className={pricestyle.td}>N 30,000</td>
                                </tr>
                                <tr>
                                    <td className={pricestyle.td}>2 Weeks</td>
                                    <td className={pricestyle.td}>N 50,000 </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                        <ul className={`${pricestyle.examTypeContainer}  ${pricestyle.marginTop} ${pricestyle.marginDown}`}>
                            <li className={pricestyle.classType}>
                                Physical Class
                            </li>
                            <li className={pricestyle.classType}>
                                Online Class
                            </li>
                        </ul>
                    <section>
                        <div>
                            <p className={pricestyle.quesP}>Do you want study packs?</p>
                            <label htmlFor=""><input type="radio" name="" id="" value="Yes" className={`${tutorstyle.radBtn2} ${tutorstyle.radBtn3}`} /> Yes</label>
                            <label htmlFor="" style={{marginLeft:"5px"}}><input type="radio" name="" id="" value="No" className={`${tutorstyle.radBtn2} ${tutorstyle.radBtn3}`} />No</label>
                        </div>
                        <div>
                            <p className={pricestyle.quesP}>Are you applying for the Examination</p>
                            <label htmlFor=""><input type="radio" name="" id="" value="Yes" className={`${tutorstyle.radBtn2} ${tutorstyle.radBtn3}`} />Yes</label>
                            <label htmlFor="" style={{marginLeft:"5px"}}><input type="radio" name="" id="" value="No" className={`${tutorstyle.radBtn2} ${tutorstyle.radBtn3}`} />No</label>
                        </div>
                    </section>
                    <section>
                        <h5 className={`${pricestyle.mainListLiH5} ${pricestyle.priceH5}`}>Personal Tutor Type</h5>
                        <ul className={pricestyle.examTypeContainer}>
                            <li className={pricestyle.classType}>
                                Physical Tutor
                            </li>
                            <li className={pricestyle.classType}>
                                Virtual Tutor
                            </li>
                        </ul>
                    </section>
                    <div>
                        <p className={pricestyle.quesP}>Are you applying for the Examination</p>
                        <label htmlFor=""><input type="radio" name="" id="" value="Yes" className={`${tutorstyle.radBtn2} ${tutorstyle.radBtn3}`} />Yes</label>
                        <label htmlFor="" style={{marginLeft:"5px"}} ><input type="radio" name="" id="" value="No" className={`${tutorstyle.radBtn2} ${tutorstyle.radBtn3}`} />No</label>
                    </div>
                    <section style={{overflowX:"auto"}}>
                        <table className={pricestyle.table}>
                            <th className={pricestyle.th}>Examination</th>
                            <th className={pricestyle.th}>Price</th>
                            <tbody>
                                <tr>
                                    <td className={pricestyle.td}>IELTS</td>
                                    <td className={pricestyle.td}>N 90,000</td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section>
                        <h5 className={pricestyle.quesP}>Create a Profile</h5>
                        <form action="" className={pricestyle.form}>
                            <label htmlFor="">Full name</label>
                            <input type="text" name="" id=" "placeholder="Enter your first and last name" className={tutorstyle.input} />
                            {/* <label htmlFor="" className={tutorstyle.listmargin}>Tutor Type</label> */}
                            <select name="" id="" className={`${tutorstyle.input} ${tutorstyle.listmargin}`}>
                                <option value="">Tutor Type</option>
                                <option value=""></option>
                                <option value=""></option>
                            </select>
                            <label htmlFor="" className={`${tutorstyle.listmargin} ${tutorstyle.inputDiv} ${pricestyle.labelPadding}`}>Address of residence</label>
                            <input type="text" name="" id="" className={tutorstyle.input} />
                            <label htmlFor="" className={`${tutorstyle.listmargin} ${tutorstyle.inputDiv} ${pricestyle.labelPadding}`}>Email</label>
                            <input type="email" name="" id="" className={tutorstyle.input} />
                            <label htmlFor="" className={`${tutorstyle.listmargin} ${tutorstyle.inputDiv} ${pricestyle.labelPadding}`}>Password</label>
                            <input type="password" name="" id="" className={tutorstyle.input} />
                            <label htmlFor="" className={`${tutorstyle.listmargin} ${tutorstyle.inputDiv} ${pricestyle.labelPadding}`}>Avatar</label>
                            <div className={pricestyle.avatarContainer}>
                                <span className={pricestyle.avatar}></span>
                            <div className={`${tutorstyle.fileBox} ${tutorstyle.fileBox2}`} style={{marginLeft:"30px"}}>
                                <input type="file" name="" id="file" className={`${tutorstyle.files}`} style={{paddingTop:"3px", display:"none"}} placeholder="cert pdf" />
                                <label htmlFor="file" className={tutorstyle.fileLabel}>Choose file</label>
                        </div>
                            </div>
                        </form>
                    </section>
                </div>
                <div className={pricestyle.divPrice}>
                    <section className={pricestyle.secondSec}>
                        <figure>
                            <img src="" alt="" srcset="" className={pricestyle.avatar} />
                            <figcaption>Hello</figcaption>
                        </figure>
                        <table className={pricestyle.table}>
                            <tbody>
                                <tr>
                                    <td className={pricestyle.td2}>TRAINING CLASS</td>
                                    <td className={pricestyle.td3}>N 20,000</td>
                                </tr>
                                <tr>
                                    <td className={pricestyle.td2}>EXAM FEES</td>
                                    <td className={pricestyle.td3}>N 20,000</td>
                                </tr>
                                <tr>
                                    <td className={pricestyle.td2}>STUDY PACK</td>
                                    <td className={pricestyle.td3}>N 20,000</td>
                                </tr>
                                <tr>
                                    <td className={`${pricestyle.td2} ${pricestyle.tdBorder}`}>TOTAL</td>
                                    <td className={`${pricestyle.td3} ${pricestyle.tdBorder}`}>N 20,000</td>
                                </tr>
                            </tbody>
                        </table>
                        <button className={`${tutorstyle.btn} ${tutorstyle.inputDiv}`}>CHECK OUT</button>
                    </section>
                </div>
            </main>
        </div>
    )
}

export default ExamPrice
