import React from 'react'
import styles from './authMisc.module.css'
import { Link } from 'react-router-dom'
function AuthResetEmail() {
    return (
        <div className={`${styles.resetContainer} ${styles.resetContainerPadding}`}>
            <div className={`${styles.imageBackground} ${styles.marginReset1}`}></div>
            <h4 className={`${styles.h4Reset} ${styles.marginReset2}`}>Forgot Password</h4>
            <p className={`${styles.pReset}  ${styles.marginReset1} ${styles.pWidth}`}>Please enter the email associated with your MyStudyPath account. We will send a link to this email address to reset your password.</p>
            <form action="" className={styles.resetEmailForm}>
                <label htmlFor="" className={`${styles.pReset} ${styles.marginReset2}`}>Email</label>
                <input type="email" name="" id="" placeholder="Enter your email address" className={`${styles.resetInput} ${styles.marginReset3}`} />
                <button className={styles.resetButton}>
                <Link to="/reset/email/link" style={{color:"#fff", textDecoration:"none"}}>RESET PASSWORD</Link>
                </button>
            </form>
            <p className={`${styles.pReset} ${styles.pResetBold}`}>
                
                <Link to="/student/signin" style={{color:"#313059", textDecoration:"none"}}>RETURN TO LOGIN</Link>
            </p>
        </div>
    )
}

export default AuthResetEmail
