import React from 'react'
import styles from './AboutHome.module.css'

function Clients() {
    return (
        <div className={styles.DoUlContainer}>
        <h1 className={`${styles.authTFirstSideText} ${styles.TeamH1}`}>Our Partner</h1>
        <ul className={styles.ClientUl}>
            <li className={styles.ClientUlLi}>
                <figure></figure>
            </li>
            <li className={styles.ClientUlLi}>
                <figure></figure>
            </li>
            <li className={styles.ClientUlLi}>
                <figure></figure>
            </li>
            <li className={styles.ClientUlLi}>
                <figure></figure>
            </li>
            <li className={styles.ClientUlLi}>
                <figure></figure>
            </li>
            <li className={styles.ClientUlLi}>
                <figure></figure>
            </li>
            <li className={styles.ClientUlLi}>
                <figure></figure>
            </li>
            <li className={styles.ClientUlLi}>
                <figure></figure>
            </li>
            <li className={styles.ClientUlLi}>
                <figure></figure>
            </li>
        </ul>
    </div>
)
}

export default Clients
