import React from 'react'

function AuthResetPage() {
    return (
        <div>
            <div></div>
            <h4>Reset Password</h4>
            <p>Set the new password for your account so you can login and access all features</p>
            <form action="">
                <label htmlFor="">Password</label>
                <input type="password" name="" id="" placeholder="Enter your password" />
                <label htmlFor="">Confirm Password</label>
                <input type="password" name="" id="" placeholder="Confirm your password" />
                <button>LOGIN</button>
            </form>
            <p>Forgot password</p> <span>CREATE AN ACCOUNT</span>
        </div>
    )
}

export default AuthResetPage
