import React from 'react'
import studystyle from './StudyMisc.module.css'
import StudyQueHeader from './StudyQueHeader'
import { Link } from 'react-router-dom'

function StudySum(props) {
    const colorChange = {
        color: "#3C3B6E",
        padding: "8px 4px",
        borderBottom: "3px solid #3C3B6E",
        fontWeight: "bold"
    }
    return (
        <div className={studystyle.formContainer}>
            <StudyQueHeader Sum={colorChange} />
            <form action="" className={studystyle.form}>
                <div className={studystyle.inputBoxGroup3}>
                    <p className={`${studystyle.summary} ${studystyle.summaryP}`}>DEGREE LEVEL</p>
                    <label htmlFor="" className={`${studystyle.summary}`}>
                        <input type="text" name={`${props.name}DegLevel`} id={`${props.name}DegLevel`} className={studystyle.summaryInput} />
                    </label>
                </div>
                <div className={studystyle.inputBoxGroup3}>
                    <p className={`${studystyle.summary} ${studystyle.summaryP}`}>DEGREE <br /> PROGRAMME</p>
                    <label htmlFor="" className={`${studystyle.summary}`}>
                        <input type="text" name={`${props.name}Degree`} id={`${props.name}Degree`} className={studystyle.summaryInput} />
                    </label>
                </div>
                <div className={studystyle.inputBoxGroup3}>
                    <p className={`${studystyle.summary} ${studystyle.summaryP}`}>LOCATION</p>
                    <label htmlFor="" className={`${studystyle.summary}`}>
                        <input type="text" name={`${props.name}Location`} id={`${props.name}Location`} className={studystyle.summaryInput} />
                    </label>
                </div>
                <div className={studystyle.inputBoxGroup3}>
                    <p className={`${studystyle.summary} ${studystyle.summaryP}`}>DURATION</p>
                    <label htmlFor="" className={`${studystyle.summary}`}>
                        <input type="text" name={`${props.name}Duration`} id={`${props.name}Duration`} className={studystyle.summaryInput} />
                    </label>
                </div>
                <div className={studystyle.inputBoxGroup3}>
                    <p className={`${studystyle.summary} ${studystyle.summaryP}`}><b>ADMISSION <br /> PLACEMENT</b></p>
                    <label htmlFor="" className={`${studystyle.summary} ${studystyle.sumLastLabel}`}>
                        <input type="text" name="" id="" value="$" contentEditable="false" className={studystyle.summaryInput2} />
                        <input type="text" name={`${props.name}Amount`} id={`${props.name}Amount`} className={studystyle.summaryInput3} />
                    </label>
                </div>
                <button className={studystyle.btn}>
                    <Link to="/" style={{color:"#fff", textDecoration:"none"}}>CHECK OUT</Link>                    
                </button>
            </form>
        </div>
    )
}

export default StudySum
