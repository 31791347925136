import React from 'react'
import GeneralHeader1 from './GeneralHeader1'
import styles from './AboutHome.module.css'
import Clients from './Clients'
import Guaranteed from './Guaranteed'
import Progress from './Progress'
import ProgressReal from './ProgressReal'
import Enquiry from './Enquiry'
import Footer from './Footer'
import authstyles from './auth.module.css'
import { Link } from 'react-router-dom'
import {ReactComponent as Gallery1} from './images/Tosin/gallery1.svg';
import {ReactComponent as Gallery2} from './images/Tosin/gallery2.svg';
import {ReactComponent as Gallery3} from './images/Tosin/gallery3.svg';
import {ReactComponent as Gallery4} from './images/Tosin/gallery4.svg';
import {ReactComponent as Gallery5} from './images/Tosin/gallery5.svg';
import {ReactComponent as Gallery6} from './images/Tosin/gallery6.svg';
import {ReactComponent as Gallery7} from './images/Tosin/gallery7.svg';
import {ReactComponent as Gallery8} from './images/Tosin/gallery8.svg';
import {ReactComponent as Gallery9} from './images/Tosin/gallery9.svg';

function Gallery(props) {
    return (
        <div>
            <GeneralHeader1 />
            <div style={{paddingTop:"20px", backgroundColor:"#3C3B6E"}}>
            <section className={`${styles.firstSection} ${styles.galleryContainer}`}>
                <div className={`${styles.firstSectionDiv} ${styles.galleryImage} ${styles.gallerySectionDiv}`}>
                    <h1 className={`${styles.firstSectionDivP} ${styles.GalleryCompH1}`}>
                        GALLERY
                    </h1>
                </div>
            </section>
            </div>
            <main>
                <section>
                    <div className={styles.topButtonContainer}>
                        <section className={authstyles.buttonSectionGallery}>
                            <button style={{backgroundColor:props.backgroundColor1,color:props.color1}} className={authstyles.topButtons}>
                                <Link to="/gallery" style={{color:props.color1, textDecoration:"none"}}>Pictures</Link>                    
                            </button>
                            <button style={{backgroundColor:props.backgroundColor2,color:props.color2}}className={authstyles.topButtons}>
                                <Link to="/gallery/video" style={{color:props.color2, textDecoration:"none"}}>Videos</Link>                    
                            </button>
                        </section>
                    </div>
                    <div className={styles.galContainer}>
                    <ul className={styles.shortGallery}>
                        <li className={styles.GalleryUlLi}>
                            <Gallery1 />
                        </li>
                        <li className={styles.GalleryUlLi}>
                            <Gallery2 />
                        </li>
                        <li className={styles.GalleryUlLi}>
                            <Gallery3 />
                        </li>
                        <li className={styles.GalleryUlLi}>
                            <Gallery4 />
                        </li>
                        <li className={styles.GalleryUlLi}>
                            <Gallery5 />
                        </li>
                        <li className={styles.GalleryUlLi}>
                            <Gallery6 />
                        </li>
                        <li className={styles.GalleryUlLi}>
                            <Gallery7 />
                        </li>
                        <li className={styles.GalleryUlLi}>
                            <Gallery8 />
                        </li>
                        <li className={styles.GalleryUlLi}>
                            <Gallery9 />
                        </li>
                    </ul>
                    </div>
                </section>
                <Clients />
            </main>
            <Guaranteed />
            <Progress />
            <ProgressReal />
            <Enquiry />
            <Footer />
        </div>
    )
}

export default Gallery
