import React from 'react'
import AuthSignUpS from './AuthSignUpS'

function AuthSignUpSError() {
    return (
        <AuthSignUpS display="block" border="1px solid #F06C6E" />
    )
}

export default AuthSignUpSError
