import './App.css';
import React from "react";
import Home from './components/Home';
import { BrowserRouter, Route, Switch} from 'react-router-dom'
// import { BrowserRouter, HashRouter, Route, Switch} from 'react-router-dom'
import About from './components/About';
import Exam from './components/Exam';
import ExamCheckOut from './components/ExamCheckOut';
import ExamCheckOutSuccessful from './components/ExamCheckOutSuccessful';
import Search from './components/Search';
import Contact from './components/Contact';
import Testimonials from './components/Testimonials';
import Review from './components/Review';
import FAQs from './components/FAQs';
import SDashboard from './components/SDashboard';
import SDashboardCurr from './components/SDashboardCurr';
import SDashboardTutor from './components/SDashboardTutor';
import SDashboardMessage from './components/SDashboardMessage';
import AuthResetLink from './components/AuthResetLink';
import AuthResetPage from './components/AuthResetPage';
import AuthResetEmail from './components/AuthResetEmail';
import AuthSignInS from './components/AuthSignInS';
import AuthSignInT from './components/AuthSignInT';
import AuthSignUpS from './components/AuthSignUpS';
import AuthSignUPSsuc from './components/AuthSignUPSsuc';
import AuthSignUPT from './components/AuthSignUPT';
import AuthSideTEdu from './components/AuthSideTEdu';
import AuthSideTEduWork from './components/AuthSideTEduWork';
import AuthSideSche from './components/AuthSideSche';
import AuthSideTech from './components/AuthSideTech';
import AuthSideSub from './components/AuthSideSub';
import AuthSideTConf from './components/AuthSideTConf';
import Gallery from './components/Gallery';
import StudyAbroad from './components/StudyAbroad';
import StudyUSA from './components/StudyUSA';
import StudyTurkey from './components/StudyTurkey';
import StudyCanada from './components/StudyCanada';
import StudyDubai from './components/StudyDubai';
import AuthSignUpSError from './components/AuthSignUpSError';
import AuthSignInSError from './components/AuthSignInSError';
import AuthSignUpTEmail from './components/AuthSignUpTEmail';
import StudyusaPI from './components/StudyusaPI';
import StudyusaEB from './components/StudyusaEB';
import StudyusaEI from './components/StudyusaEI';
import StudyusaSum from './components/StudyusaSum';
import StudyturkeyPI from './components/StudyturkeyPI';
import StudyturkeyEB from './components/StudyturkeyEB';
import StudyturkeyEI from './components/StudyturkeyEI';
import StudyturkeySum from './components/StudyturkeySum';
import StudycanadaPI from './components/StudycanadaPI';
import StudycanadaEB from './components/StudycanadaEB';
import StudycanadaEI from './components/StudycanadaEI';
import StudycanadaSum from './components/StudycanadaSum';
import StudydubaiPI from './components/StudydubaiPI';
import StudydubaiEB from './components/StudyDubaiEB';
import StudydubaiEI from './components/StudydubaiEI';
import StudydubaiSum from './components/StudydubaiSum';
import ExamPriceContainer from './components/ExamPriceContainer';
import GalleryPlay from './components/GalleryPlay';
import ErrorPage from './components/ErrorPage';
import 'bootstrap/dist/css/bootstrap.min.css';

const options = [
  { value: "Spring", label: "Spring", color: "#498205" },
  { value: "Summer", label: "Summer", color: "#c19c00" },
  { value: "Autumn", label: "Autumn", color: "#da3b01" },
  { value: "Winter", label: "Winter", color: "#004e8c" }
];

const styles = {
  option: (provided, state) => ({
    ...provided,
    fontWeight: state.isSelected ? "bold" : "normal",
    color: "white",
    backgroundColor: state.data.color,
    fontSize: state.selectProps.myFontSize
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.data.color,
    fontSize: state.selectProps.myFontSize
  }),
  control: base => ({
    ...base,
    border: "1px solid #3C3B6E;",
    marginTop: "20px",
    height: 25,
    minHeight:25,
    // This line disable the blue border
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #3C3B6E;',
  }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '25px',
    padding: '0px 5px;',
    paddingBottom: '30px'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '30px',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    paddingTop: 5,
    paddingBottom: 5,
  }),
  clearIndicator: (styles) => ({
      ...styles,
      paddingTop: 7,
      paddingBottom: 7,
  }),
};

function App() {
  return (
    <div>
      {/* <BrowserRouter basename={process.env.PUBLIC_URL}> */}
      {/* <HashRouter>         */}
      <BrowserRouter basename="/fngc">
      <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/gallery">
            <Gallery backgroundColor1="#3C3B6E" color1="#fff" backgroundColor2="#fff" color2="#313059" />
          </Route>
          <Route exact path="/gallery/video">
            <GalleryPlay backgroundColor1="#fff" color2="#fff" backgroundColor2="#3C3B6E" color1="#313059" />
          </Route>

          <Route exact path="/exam">
            <Exam />
          </Route>
          <Route exact path="/exam/examprice">
            <ExamPriceContainer />
          </Route>
          <Route exact path="/exam/checkout">
            <ExamCheckOut />
          </Route>
          <Route exact path="/exam/checkoutsuccess">
            <ExamCheckOutSuccessful />
          </Route>
          <Route exact path="/search">
            <Search />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route exact path="/testimonials">
            <Testimonials />
          </Route>
          <Route exact path="/review">
            <Review />
          </Route>
          <Route exact path="/faq">
            <FAQs />
          </Route>
          <Route exact path="/dashboard">
            <SDashboard />
          </Route>
          <Route exact path="/dashboard/curriculum">
            <SDashboardCurr />
          </Route>
          <Route exact path="/dashboard/tutor">
            <SDashboardTutor />
          </Route>
          <Route exact path="/dashboard/message">
            <SDashboardMessage />
          </Route>
          <Route exact path="/reset/email/link">
            <AuthResetLink />
          </Route>
          <Route exact path="/reset/newpassword">
            <AuthResetPage />
          </Route>
          <Route exact path="/reset/email">
            <AuthResetEmail />
          </Route>
          <Route exact path="/student/signin">
            <AuthSignInS backgroundColor1="#3C3B6E" color1="#fff" backgroundColor2="#fff" color2="#000" />
          </Route>
          <Route exact path="/tutor/signin">
            <AuthSignInT />
          </Route>
          <Route exact path="/student/signup">
            <AuthSignUpS backgroundColor1="#3C3B6E" color1="#fff" backgroundColor2="#fff" color2="#000" />
          </Route>
          <Route exact path="/student/signin/success">
            <AuthSignUPSsuc />
          </Route>
          <Route exact path="/tutor/signup">
            <AuthSignUPT myFontSize="20px" options={options} styles={styles}/>
          </Route>
          <Route exact path="/tutor/education">
            <AuthSideTEdu />
          </Route>
          <Route exact path="/tutor/workexperience">
            <AuthSideTEduWork />
          </Route>
          <Route exact path="/tutor/schedule">
            <AuthSideSche />
          </Route>
          <Route exact path="/tutor/technicalspec">
            <AuthSideTech />
          </Route>
          <Route exact path="/tutor/subjectspecialty">
            <AuthSideSub />
          </Route>
          <Route exact path="/tutor/nda">
            <AuthSideTConf />
          </Route>
          <Route exact path="/study/abroad">
            <StudyAbroad />
          </Route>
          <Route exact path="/study/abroad/usa">
            <StudyUSA />
          </Route>
          <Route exact path="/study/abroad/usa/pi">
            <StudyusaPI />
          </Route>
          <Route exact path="/study/abroad/usa/eb">
            <StudyusaEB />
          </Route>
          <Route exact path="/study/abroad/usa/ei">
            <StudyusaEI />
          </Route>
          <Route exact path="/study/abroad/usa/sum">
            <StudyusaSum />
          </Route>
          <Route exact path="/study/abroad/turkey">
            <StudyTurkey />
          </Route>
          <Route exact path="/study/abroad/turkey/pi">
            <StudyturkeyPI />
          </Route>
          <Route exact path="/study/abroad/turkey/eb">
            <StudyturkeyEB />
          </Route>
          <Route exact path="/study/abroad/turkey/ei">
            <StudyturkeyEI />
          </Route>
          <Route exact path="/study/abroad/turkey/sum">
            <StudyturkeySum />
          </Route>
          <Route exact path="/study/abroad/canada">
            <StudyCanada />
          </Route>
          <Route exact path="/study/abroad/canada/pi">
            <StudycanadaPI />
          </Route>
          <Route exact path="/study/abroad/canada/eb">
            <StudycanadaEB />
          </Route>
          <Route exact path="/study/abroad/canada/ei">
            <StudycanadaEI />
          </Route>
          <Route exact path="/study/abroad/canada/sum">
            <StudycanadaSum />
          </Route>
          <Route exact path="/study/abroad/dubai">
            <StudyDubai />
          </Route>
          <Route exact path="/study/abroad/dubai/pi">
            <StudydubaiPI />
          </Route>
          <Route exact path="/study/abroad/dubai/eb">
            <StudydubaiEB />
          </Route>
          <Route exact path="/study/abroad/dubai/ei">
            <StudydubaiEI />
          </Route>
          <Route exact path="/study/abroad/dubai/sum">
            <StudydubaiSum />
          </Route>
          <Route exact path="/student/signup/error">
            <AuthSignUpSError />
          </Route>
          <Route exact path="/student/signin/error">
            <AuthSignInSError />
          </Route>
          <Route exact path="/tutorSignUpForm">
            <AuthSignUpTEmail />
          </Route>
          <Route exact path="/error">
            <ErrorPage />
          </Route>
        </Switch>
        {/* </HashRouter> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
