import React from 'react'

function SDashboardSide() {
    return (
        <section>
            <figure>
                <figcaption>
                    <p>Name</p>
                    <a href="mailto:tutorgmail.com">tutorgmail.com</a>
                </figcaption>
            </figure>
            <ul>
                <li>Dashboard</li>
                <li>Curriculum</li>
                <li>Get a tutor</li>
                <li>Messages</li>
            </ul>
            <ul>
                <li>Settings</li>
                <li>Toggle sidebar</li>
            </ul>
        </section>
     )
}

export default SDashboardSide
