import React from 'react'
import studystyle from './Study.module.css'
import homestyle from './AboutHome.module.css'
import { Link } from 'react-router-dom'
function StudyBottom(props) {
    return (
        <div>
            <p className={`${studystyle.pageSideH4} ${studystyle.disclaimer}`}>Disclaimer</p>
            <p className={studystyle.pageP}>FNG is not responsible for the <b>VISA APPROVAL.</b> FNG shall no be responsible for extra requirement such as WES, CASper test, candidate’s Medical Tests and Report, Bank Accounts.</p>
            <button className={`${homestyle.firstSectionDivButton} ${studystyle.submitBtn}`}>
                <Link to={props.location} style={{color:"#fff", textDecoration:"none"}}>APPLY NOW</Link>                    
            </button>
        </div>
    )
}

export default StudyBottom
