import React from 'react'
import AuthSideT from './AuthSideT'
import GeneralHeader1 from './GeneralHeader1'
import tutorstyle from './TutorAuth.module.css'
import { Link } from 'react-router-dom'
function AuthSideTConf() {
    return (
        <div>
            <GeneralHeader1 />
            <main className={tutorstyle.BigContainer}>
                <div className={tutorstyle.Container}>
                <AuthSideT />
                <section className={tutorstyle.secondSide}>
                    <h4 className={tutorstyle.secondSideH4}>Confidentiality &#38; Non-Disclosure</h4>
                        <form action="" className={tutorstyle.form}>
                            <h5 className={tutorstyle.formH5}>NDA</h5>
                            <div className={tutorstyle.formDiv}>
                                <p className={tutorstyle.formList2}>Please read the following <b>FNG Non-Disclosure Agreement.</b> You will need to check off that you have read this agreement before proceeding:</p>

                                <div className={`${tutorstyle.formListContainer} ${tutorstyle.flexBtn} ${tutorstyle.listmargin}`}>
                                    <span className={tutorstyle.spanBtn}>
                                        <input type="checkbox" name="" id="" className={tutorstyle.radBtn} />
                                    </span>
                                    <label htmlFor="" className={tutorstyle.labelBtn}>
                                        I have read and agree to the <b>FNG Non-Disclosure Agreement.</b>
                                    </label>
                                </div>
                                <p className={`${tutorstyle.list} ${tutorstyle.listmargin}`}>Please read the following statement and answer according: i hereby certify that the above information, to the best of my knowledge, is correct. I understand that falsification of this information may prevent me from being accepted as a tutor or lead to my dismissal if my application is accepted,:*</p>
                                <div className={`${tutorstyle.formListContainer} ${tutorstyle.flexBtn} ${tutorstyle.listmargin}`}>
                                    <span className={tutorstyle.spanBtn}>
                                        <input type="radio" name="" id="" className={tutorstyle.radBtn} />
                                    </span>                     
                                    <label htmlFor="" className={tutorstyle.labelBtn}>Yes, i hereby certify that the above information, to the best of my knowledge, is correct. I understand that falsification of this information may prevent me from being accepted as a tutor or lead to my dismissal if my application is accepted.</label>
                                </div>
                                <div className={`${tutorstyle.formListContainer} ${tutorstyle.flexBtn} ${tutorstyle.listmargin}`}>
                                    <span className={tutorstyle.spanBtn}>
                                        <input type="radio" name="" id="" className={tutorstyle.radBtn} />
                                    </span>                     
                                    <label htmlFor="" className={tutorstyle.labelBtn}>I do not agree with statement above, and therefore withdraw my application from consideration.</label>
                                </div>
                            </div>
                            <button className={`${tutorstyle.btn} ${tutorstyle.btnFirstChild}`}>
                                <Link to="/tutor/subjectspecialty" style={{color:"#fff", textDecoration:"none"}}>BACK</Link>
                            </button>
                            <button className={`${tutorstyle.btn} ${tutorstyle.btnLastChild}`}>
                                <Link to="/" style={{color:"#fff", textDecoration:"none"}}>SUBMIT</Link>
                            </button>
                        </form>
                </section>
                </div>
            </main>
        </div>
    )
}

export default AuthSideTConf
