import React from 'react'
import styles from './authMisc.module.css'

function AuthResetLink() {
    return (
        <div className={styles.resetContainer}>
                <div className={`${styles.imageBackground2} ${styles.marginReset1}`}></div>
                <h4 className={`${styles.h4Reset} ${styles.marginReset2}`}>Password Reset Link Sent</h4>
                <p className={`${styles.pReset} ${styles.pWidth}`}>A password rest link with instructions on how to reset your password has been sent to your email <span>sa</span>******gmail.com. Check your inbox and click on the link provided.</p>
        </div>
    )
}

export default AuthResetLink
