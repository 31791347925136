import React from 'react'
import styles from './auth.module.css';
import { Link } from 'react-router-dom';
import GeneralHeader1 from './GeneralHeader1';
function AuthSignUpS(props) {
    return (
      <div>
      <GeneralHeader1 />
      <div className={styles.centerContainer}>

        <div className={`${styles.flexBox1} ${styles.wrap1} ${styles.container}`}>
          <div className={styles.fig1}></div>  
          <div className={`${styles.padding2} ${styles.authSide}`}>
              <section className={`${styles.sideShadow} ${styles.sideShadowM} ${styles.authenticationBox}`}>
                  <div className={styles.fngc}></div>
                  <form action="" className={`${styles.authSideForm} ${styles.flexBox2}  ${styles.flexBox1}`}>
                      <p className={styles.formPara}>Hello, <br/> <span className={styles.formParaSpan}>Please sign up to continue</span></p>
                      <label htmlFor="" className={styles.label}>Full name</label>
                      <input type="text" name="" id="" placeholder="Enter your first and last name" className={styles.input} style={{border:props.border}} />
                      <p className={styles.pError} style={{display:props.display}}>Name required</p>
                      <label htmlFor="" className={styles.label}>Email</label>
                      <input type="email" name="" id="" placeholder="Enter your email address" className={styles.input} style={{border:props.border}} />
                      <p className={styles.pError} style={{display:props.display}}>Email required</p>
                      <label htmlFor="" className={styles.label}>Password</label>
                      <input type="password" name="" id=""placeholder="Enter your password" className={styles.input} style={{border:props.border}} />
                      <p className={styles.pError} style={{display:props.display}}>password required</p>
                      <button className={`${styles.input} ${styles.button}`}>
                          <Link to="/" style={{color:"#fff", textDecoration:"none"}}>SIGN UP</Link>
                    </button>
                      <p className={`${styles.formParaSpan} ${styles.already}`}>Already have an account? 
                        <span className={styles.para}>
                        <Link to="/student/signin" style={{color:"#313059", textDecoration:"none"}}>LOGIN</Link>
                        </span>
                        </p>
                  </form>
              </section>
          </div>
        </div>
        </div>
        </div>
    )
}

export default AuthSignUpS
