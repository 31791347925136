import React from 'react'
import styles from './auth.module.css'


function AuthSignUPSsuc() {
    return (
        <div className={`${styles.flexBox1} ${styles.JContent1} ${styles.AItem1}`}>
            <div></div>
            <section>
                <h4>Success!</h4>
                <p>Your account has been successfully created. We have sent you an email with your registration details and a link to verify your email. Please click on the link in your email to verify your account.</p>
            </section>
        </div>
    )
}

export default AuthSignUPSsuc
