import React from 'react'
import btnstyle from './Exam.module.css'
function ExamButton() {
    return (
        <div className={btnstyle.btnDiv}>
            <button className={btnstyle.theBtns}>TUTOR CLASS</button>
            <button className={`${btnstyle.theBtns} ${btnstyle.secBtn}`}>EXAMINATION REGISTRATION</button>
        </div>
    )
}

export default ExamButton
