import React from 'react'
import studystyle from './StudyMisc.module.css'
function StudyQueHeader(props) {
    return (
        <div>
            <p className={studystyle.headerP}>Please fill the form below to apply</p>
            <ul className={studystyle.headerUl}>
                <li className={studystyle.PI} style={props.PI}>Personal Information</li>
                <li className={studystyle.EB} style={props.EB}>Educational Background</li>
                <li className={studystyle.EI} style={props.EI}>Educational Interest</li>
                <li className={studystyle.Sum} style={props.Sum}>Summary</li>
            </ul>
        </div>
    )
}

export default StudyQueHeader
