import React, {useState} from 'react'
import studystyle from './Study.module.css'
import { Link } from 'react-router-dom'
function StudySide() {
    
    const [usa, setUsa] = useState(false);
    const [turkey, setTurkey] = useState(false);
    const [canada, setCanada] = useState(false);
    const [dubai, setDubai] = useState(false);


    const handleUSAClick = () => {
        if (window.innerWidth <=  600){
            setTurkey(false);
            setCanada(false);
            setUsa(!usa);
            setDubai(false);

        }
    }

    const handleTurkeyClick = () => {
        if (window.innerWidth <=  600){
            setTurkey(!turkey);
            setCanada(false);
            setUsa(false);
            setDubai(false);
        }
    }
    const handleCanadaClick = () => {
        if (window.innerWidth <=  600){
            setCanada(!canada)
            setTurkey(false);
            setUsa(false);
            setDubai(false);
        }
    }
    const handleDubaiClick = () => {
        if (window.innerWidth <=  600){
            setCanada(false)
            setTurkey(false);
            setUsa(false);
            setDubai(!dubai);
        }
    }


    console.log(turkey, " turkey", canada, "canada", usa, " usa", dubai, "dubai")
    return (
        <section className={studystyle.pageSide1}>
            <ul className={studystyle.listContainer}>
                <Link to="/study/abroad/usa" style={{color:"#fff", textDecoration:"none", width:"100%"}}>
                <li className={`${studystyle.list} ${studystyle.list1}`}  onClick={handleUSAClick} style={{ borderBottom: usa ? "3px solid #ff0000" : ""}}>Study in USA</li>
                </Link>
                
                <Link to="/study/abroad/turkey" style={{color:"#fff", textDecoration:"none", width:"100%"}}>
                    <li className={`${studystyle.list} ${studystyle.list1}`}  onClick={handleTurkeyClick} style={{ borderBottom: turkey ? "3px solid #ff0000" : "" }}>Study in Turkey</li>
                </Link>
                <Link to="/study/abroad/canada" style={{color:"#fff", textDecoration:"none", width:"100%"}} >                
                    <li className={`${studystyle.list} ${studystyle.list1}`} onClick={handleCanadaClick} style={{ borderBottom: canada ? "3px solid #ff0000" : "" }}>Study in Canada</li>
                </Link>
                <Link to="/study/abroad/dubai" style={{color:"#fff", textDecoration:"none", width:"100%"}} >
                    <li className={`${studystyle.list} ${studystyle.list1}`} onClick={handleDubaiClick} style={{ borderBottom: dubai ? "3px solid #ff0000" : "" }}>Study in Dubai</li>
                </Link>

            </ul>
        </section>

    )
}

export default StudySide
