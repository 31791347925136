import React from 'react'
import Footer from './Footer'
import GeneralHeader1 from './GeneralHeader1'
import examstyle from './Exam.module.css'
import homestyle from './AboutHome.module.css'
import ExamButton from './ExamButton'
import ExamPrice from './ExamPrice'

function ExamPriceContainer() {
    const faint = {
        opacity:0.3,
        backgroundColor:"rgba(0, 0, 0, 0.4)"
    }

    return (
        <div>
            <GeneralHeader1 />
           <section className={`${homestyle.firstSection} ${examstyle.firstSection}`} style={faint}>
               <div className={homestyle.firstSectionDiv}>
                <h4 className={examstyle.firstSectionDivH4}>Examination Preparations</h4>
                <p className={examstyle.firstSectionDivP}>Take the next step with confidence</p>
                <button className={`${homestyle.firstSectionDivButton} ${examstyle.firstSectionDivButton}`}>APPLY NOW</button>
               </div>
           </section> 
           <main style={faint}>
                <p className={examstyle.mainP}>No matter where you’re at in your education or career, FNG will help you reach the next level with confidence. Choose a study option that fits your life: in-classroom, online, private tutoring or self-paced with give you an edge.</p>
               <section className={examstyle.mainFSection}>
                   <ul className={`${examstyle.mainP} ${examstyle.mainListsContainer} ${examstyle.mainUl}`}>
                       <li className={examstyle.width}>
                           <h5 className={examstyle.mainListLiH5}>HIGH SCHOOL EXAMS</h5>
                           <p className={examstyle.mainSmallP}><a href="http://">YOS</a></p>
                       </li>
                       <li className={examstyle.width}>
                            <h5 className={examstyle.mainListLiH5}>COLLEGE ENTRANCE EXAMS</h5>
                           <p className={`${examstyle.mainSmallP} ${examstyle.satP}`}><a href="http://">SAT</a></p>
                       </li>
                    </ul>
                    <ul className={`${examstyle.mainP} ${examstyle.mainListsContainer} ${examstyle.mainUl}`}>
                       <li className={examstyle.width}>
                            <h5 className={examstyle.mainListLiH5}>GRADUATE SCHOOL ENTRANCE EXAMS</h5>
                           <p className={examstyle.mainSmallP}><a href="http://">GRE</a></p>
                           <p className={examstyle.mainSmallP}><a href="http://">GMAT</a></p>
                       </li>
                       <li className={examstyle.width}>
                            <h5 className={examstyle.mainListLiH5}>ENGLISH LANGUAGE EXAM PREPARATION</h5>
                           <p className={examstyle.mainSmallP}><a href="http://">TOEFL</a></p>
                            <p className={examstyle.mainSmallP}><a href="http://">IELTS</a></p>
                       </li>
                   </ul>
               </section>
               <section className={examstyle.mainFSection}>
                   <div>
                        <h5 className={examstyle.mainListLiH5}>HIGH SCHOOL EXAMS</h5>
                        <p className={examstyle.mainSmallP}><b>YOS</b> Examination is an entrance examination designed for foreign students wishing to study in higher education institutions in Turkey.</p>
                        <ExamButton />
                   </div>
                   <div>
                        <h5 className={examstyle.mainListLiH5}>COLLEGE ENTRANCE EXAMS</h5>
                        <p className={examstyle.mainSmallP}><b>SAT</b> is a standardized test widely used for college admissions in the United States. Since its debut in 1926, its name and scoring have changed several times; originally called the Scholastic Aptitude Test, it was later called the Scholastic Assessment Test, then the SAT I: Reasoning Test, then the SAT Reasoning Test, then simply the SAT. The SAT is wholly owned, developed, and published by the College Board, a private, not-for-profit organization in the United States. It is administered on behalf of the College Board by the Educational Testing Service, which until recently developed the SAT as well. The test is intended to assess students' readiness for college.</p>
                        <ExamButton />
                   </div>
                   <div>
                        <h5 className={examstyle.mainListLiH5}>GRADUATE SCHOOL ENTRANCE EXAMS</h5>
                        <p className={examstyle.mainSmallP}>The Graduate Record Examinations (<b>GRE</b>) is a standardized test that is an admissions requirement for many graduate schools in the United States and Canada and few in other countries. The GRE is owned and administered by Educational Testing Service (ETS). The test was established in 1936 by the Carnegie Foundation for the Advancement of Teaching. <br /> According to ETS, the GRE aims to measure verbal reasoning, quantitative reasoning, analytical writing, and critical thinking skills that have been acquired over a long period of learning. The content of the GRE consists of certain specific algebra, geometry, arithmetic, and vocabulary sections. The GRE General Test is offered as a computer-based exam administered at testing centers and institution owned or authorized by Prometric. In the graduate school admissions process, the level of emphasis that is placed upon GRE scores varies widely between schools and departments within schools. The importance of a GRE score can range from being a mere admission formality to an important selection factor.</p>
                        <ExamButton />
 
                        <p className={examstyle.mainSmallP}>The Graduate Management Admission Test (<b>GMAT</b>) is a computer adaptive test (CAT) intended to assess certain analytical, writing, quantitative, verbal, and reading skills in written English for use in admission to a graduate management program, such as an MBA program. It requires knowledge of certain specific grammar and knowledge of certain specific algebra, geometry, and arithmetic. According to the test-owning company, the Graduate Management Admission Council (GMAC), the GMAT assesses analytical writing and problem-solving abilities while also addressing data sufficiency, logic, and critical reasoning skills that it believes to be vital to real-world business and management success. It can be taken up to five times a year but no more than eight times total. Attempts must be at least 16 days apart</p>
                        <ExamButton />
                   </div>
                   <div>
                        <h5 className={examstyle.mainListLiH5}>ENGLISH LANGUAGE EXAM PREPARATION</h5>
                        <p className={examstyle.mainSmallP}>Test of English as a Foreign Language (<b>TOEFL</b>) is a standardized test to measure the English language ability of non-native speakers wishing to enroll in English-speaking universities. The test is accepted by more than 11,000 universities and other institutions in over 150 countries. TOEFL is one of the two major English-language tests in the world, the other being the IELTS.</p>
                        <ExamButton />
 
                        <p className={examstyle.mainSmallP}>TThe International English Language Testing System (<b>IELTS</b>) is an international standardized test of English language proficiency for non-native English language speakers. It is jointly managed by the British Council, IDP: IELTS Australia and Cambridge Assessment English, and was established in 1989. IELTS is one of the major English-language tests in the world. <br /> It is accepted by most Australian, British, Canadian, European, Irish and New Zealand academic institutions, by over 3,000 academic institutions in the United States, and by various professional organisations across the world. It is the only Secure English Language Test approved by UK Visas and Immigration (UKVI) for visa customers applying both outside and inside the UK. <br /> No minimum score is required to pass the test. An IELTS result or Test Report Form is issued to all test takers with a score from "band 1" ("non-user") to "band 9" ("expert user") and each institution sets a different threshold. There is also a "band 0" score for those who did not attempt the test. Institutions are advised not to consider a report older than two years to be valid, unless the user proves that they have worked to maintain their level.</p>
                        <ExamButton />
                   </div>
               </section>
           </main>
            <Footer/>
            <ExamPrice />
        </div>
    )
}

export default ExamPriceContainer
