import React from 'react'
import GeneralHeader1 from './GeneralHeader1'
import StudyAbroadHeader from './StudyAbroadHeader'
import studystyle from './Study.module.css'
import StudySide from './StudySide'
import StudyBottom from './StudyBottom'
import Footer from './Footer'
import StudySum from './StudySum'

function StudyturkeySum() {
    const faint = {
        opacity:0.3,
        backgroundColor:"rgba(0, 0, 0, 0.4)"
    }
    return (
        <div>
            <GeneralHeader1 />
            <StudyAbroadHeader opacity={faint} />
            <main className={studystyle.countryPage} style={faint}>
                <StudySide />
                <section className={studystyle.pageSide2}>
                    <p className={studystyle.pageP}>Turkey is a center of attraction for students in the world, with its natural, spiritual, transportation and physical properties specific to its cities.</p>
                    <ul className={studystyle.listContainer}>
                        <h4 className={studystyle.pageSideH4}>Why Choosing to Study in Turkey?</h4>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Quality Higher Education Opportunities</h5>
                            <p className={studystyle.pageP}>
                            Turkey is the second country in the world in access to higher education with 94.2% schooling rate. Turkey involved in European Higher Education Area is implementing the Bologna Process in a perfect way; our Bologna report is 5 out of 5. So, the diploma you receive from a university in Turkey is recognized in all European countries! As course credit system, ECTS is applied in accordance with Europe and all students are given Diploma Supplement. In addition, Turkey is one of the most successful countries participating in the exchange programs under Erasmus +. In addition to Erasmus, there are many exchange programs in Turkey, such as Mevlana, Farabi, which support the mobility of students and lecturers.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>University And Program Diversity</h5>
                            <p className={studystyle.pageP}>
                            There are 207 universities in Turkey with a population of 82 Million. The number of students is close to 8 million. With this number of students, Turkey is the first country with the most students in European Higher Education Area. There are nearly 60.000 different programs at 207 universities. In such a variety, you will definitely find a university and program for yourself.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Multicultural Life</h5>
                            <p className={studystyle.pageP}>
                            Turkey, which has hosted many deep-rooted civilizations in its territory for thousands of years, is almost a mosaic of cultures! In this country where countless civilizations have been hosted, everyone is tolerant and respectful to each other. Turkey, where you can find a piece of your roots and perhaps can meet people who speak your language is ideal for students with its safe and peaceful environment.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Natural Beauties</h5>
                            <p className={studystyle.pageP}>
                            Turkey, which experiences four seasons, has a reputation for its natural beauties all over the world. You can enjoy swimming and water sports in the seas that surround Turkey, you can ski in many cities in the mountains, you can enjoy rafting in the rivers and experience many extreme sports. You will feel as in the heaven in Turkey where countless beauties which shall only fascinate you with their landscape!
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Historical and Cultural Heritage</h5>
                            <p className={studystyle.pageP}>
                            Based on a long history, there are thousands of historical and cultural monuments in Turkey, many of which are protected as UNESCO Cultural Heritage. Even in your daily life, you will want to keep track of the traces you may encounter frequently!
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Student Friendly</h5>
                            <p className={studystyle.pageP}>
                            With at least one university in every city, Turkey is exactly a student-friendly country! Social clubs, sports teams and cultural events at every university bring together students and  make distant geographies close. When you arrive in Turkey you will understand that the most active points of the city are the places where students socialize! Thanks to the cafes, restaurants and libraries where the students hang out, the cities live 24 hours a day.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Hospitable Turkish People</h5>
                            <p className={studystyle.pageP}>
                            The hospitality of Turkish people has been legendary all over the world! As a student, if you ask for help, people will do their utmost with all sincerity and friendliness. Turks will welcome you as guests and have many treats to satisfy you. Be sure you won't be miss your home here!
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Modern Technological Campuses</h5>
                            <p className={studystyle.pageP}>
                            No matter which one of the 207 universities you go, you will experience a modern and convenient campus life, equipped with the latest technology.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Easy Living Conditions</h5>
                            <p className={studystyle.pageP}>
                            Life in Turkey is more affordable than most countries. You can meet your needs, such as accommodation, food and drink, and entertainment at  affordable prices. You can stay at the dormitories within or near the University campuses, or rent a house for a reasonable lease. In addition, whichever transportation you choose as a student, you will receive a ticket at a discounted price, or watch the movie in the cinema at cheaper. <br />
                            Turkey is also an easy to reach country because it is in the middle Asia and Europe continents. Whichever way you choose to discover Turkey, which hosts countless places of attraction, you can arrive where you want quickly and comfortably.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Turkish Learning Opportunities</h5>
                            <p className={studystyle.pageP}>
                            Several programs in Universities in Turkey are in English. Besides you can also learn Turkish, the 5th most spoken language in the world! You can get a chance to learn a new language in Turkish courses that your University will offer you, and you can be friends with people from many different cultures.
                            </p>
                        </li>
                    </ul>
                    <StudyBottom />
                </section>
            </main>
            <Footer />
            <StudySum name="turkey" location="/" />
        </div>
    )
}

export default StudyturkeySum
