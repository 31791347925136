import React from 'react'
import AuthSideT from './AuthSideT'
import AuthSideTEduForm from './AuthSideTEduForm'
import GeneralHeader1 from './GeneralHeader1'
import tutorstyle from './TutorAuth.module.css'
import { Link } from 'react-router-dom'
function AuthSideTEdu() {
    return (
        <div>
            <GeneralHeader1 />
            <main className={tutorstyle.BigContainer}>
                <div className={tutorstyle.Container}>
                <AuthSideT />
                <section className={tutorstyle.secondSide}>
                    <h4 className={tutorstyle.secondSideH4}>Education Degree</h4>
                    <p className={tutorstyle.UpperInstructionP}>
                        <strong>Instruction: </strong>
                        Please enter the degree you are enrolled in or have earned from an accredited college or university, regardless if you have earned a higher degree outside Nigeria. If you have earned a degree solely from a university outside Nigeria, please answer accordingly.
                    </p>   
                    <p className={tutorstyle.UpperInstructionP}>
                        <b>Please Note:</b> <br />
                        If you have more than a degree, kindly state.
                    </p>
                    <form action="" className={tutorstyle.form}>
                    <h5 className={tutorstyle.formH5}>First Degree</h5>
                        <div className={tutorstyle.formDiv}>
                            <div className={tutorstyle.formList}>
                                <p>To qualify to become a tutor at FNG, you must meet one of these educational requirement:</p>
                                <div className={`${tutorstyle.formListContainer} ${tutorstyle.listmargin}`}>
                                    <p className={tutorstyle.formListStyle}></p>
                                    <p className={tutorstyle.list}>Graduated with a 4-years from an accredited Nigierian University</p>
                                </div>
                                <div className={tutorstyle.formListContainer}>
                                    <p className={tutorstyle.formListStyle}></p>
                                    <p className={tutorstyle.list}>Currently enrolled as Sophomore or higher in a 4-year degree program in an accredited university</p>
                                </div>
                                <p className={tutorstyle.listmargin}>Please indicate if you meet EITHER of these requirement<b>*</b></p>
                            </div>     
                            <div className={`${tutorstyle.formListContainer} ${tutorstyle.flexBtn}`}> 
                                <span className={tutorstyle.spanBtn}>
                                    <input type="radio" name="" id="" className={tutorstyle.radBtn} />
                                </span>                     
                                <label htmlFor="" className={tutorstyle.labelBtn}>
                                    No, i have not earned a degree from any accredited Nigerian university but i have earned a degree from an accredited foreign university and can provide proof of degree (in English) and equivalency in the process
                                </label>
                            </div>
                            <div className={`${tutorstyle.formListContainer} ${tutorstyle.flexBtn}`}>
                                <span className={tutorstyle.spanBtn}>
                                    <input type="radio" name="" id="" className={tutorstyle.radBtn} />
                                </span>
                                <label htmlFor="" className={tutorstyle.labelBtn}>
                                    No, i am not enrolled nor have i earned a degree from any Nigerian or foreign university but i am currently certified in one or more of the following computer science languages: Java, C++, Visual Basic, and/or Python and i can provide proof of current certification later on in the process
                                </label>
                            </div>
                            <div className={`${tutorstyle.formListContainer} ${tutorstyle.flexBtn}`}>
                                <span className={tutorstyle.spanBtn}>
                                    <input type="radio" name="" id=""  className={tutorstyle.radBtn} />
                                </span>
                                <label htmlFor="" className={tutorstyle.labelBtn}>
                                    No, I am not enrolled nor have i earned a degree from any accredited university but i am currently certified in a professional program and i can provide proof of certificate
                                </label>
                            </div>
                            <p className={tutorstyle.formList}>Please choose the bachelor degree you earned:<span>*</span></p>
                            <AuthSideTEduForm />
                        </div>
                        <div>
                            <h5 className={tutorstyle.formH5}>Second Degree</h5>
                            <AuthSideTEduForm border="1px solid #3C3B6E" padding="30px 22px" />
                        </div>
                        <div>
                            <h5 className={tutorstyle.formH5}>Second Degree</h5>
                            <AuthSideTEduForm border="1px solid #3C3B6E" padding="30px 22px" />
                        </div>
                        <div>
                            <h5 className={tutorstyle.formH5}>Second Degree</h5>
                            <AuthSideTEduForm border="1px solid #3C3B6E" padding="30px 22px" />
                        </div>
                        <button className={tutorstyle.btn}>
                        <Link to="/tutor/workexperience" style={{color:"#fff", textDecoration:"none"}}>NEXT</Link>
                        </button>
                    </form>
                </section>
                </div>
            </main>
        </div>
    )
}

export default AuthSideTEdu
