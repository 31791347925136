import React from 'react'
import styles from './AboutHome.module.css'
import { Link } from 'react-router-dom'

function Enquiry() {
    return (
        <div className={styles.EnquiryContainer}>
        <section className={styles.Enquiry}>
            <p className={styles.EnquiryP}>For enquiries or consultation</p>
            <p className={styles.EnquiryP2}>You can speak with out experienced staff over the phone, Skype, WhatsApp, by email or in person <span style={{color:'green', fontWeight:900}}>for free</span></p>
                <div className={styles.EnquiryDiv}>
                    <Link to="/contact" style={{color:"#313059", textDecoration:"none"}}>
                        <button className={styles.EnquiryBtn} style={{cursor:"pointer"}}>CONTACT US</button>
                    </Link>
                </div>
        </section>
        </div>

    )
}

export default Enquiry
