import React from 'react'
import styles from './footer.module.css'

function Guaranteed() {
    return (
            <h1 className={styles.Guaranteed}>GUARANTEED</h1>
    )
}

export default Guaranteed
