import React from 'react'

function GeneralHeader() {
    return (
        <header>
            <figure>
                <img src="" alt="" />
            </figure>
            <nav>
                <ul>
                    <li>Home</li>
                    <li>Schools &#38; Courses</li>
                    <li>My Application</li>
                    <li>Contact Us</li>
                </ul>
            </nav>
            <div>
                <img src="" alt="bell icon" />
                <img src="" alt="profile" />
            </div>
 
        </header>


    )
}

export default GeneralHeader
