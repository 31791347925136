import React, {useState} from 'react'
import reviewstyles from './SearchReview.module.css'
import './rating.css'
import Testy from './testy'
import Rev from './rev'

function Review(props) {
    const [writeTestimony, setWriteTestimony] = useState(true)
    const [writeReview, setWriteReview] = useState(true)

    const handleTestimony = () => {
        setWriteTestimony(false)
        setWriteReview(true)
    }
    const handleReview = () => {
        setWriteTestimony(true)
        setWriteReview(false)
    }

    return (
        <div className={props.rev ? "rateNone" : "rateBlock"}>
            <section className={reviewstyles.firstSection}>
                <div className={reviewstyles.formReview}>
                    <p className={reviewstyles.widthP1} style={{paddingLeft:0}}>Fill the fields below</p>
                    <div className={`${reviewstyles.widthDiv2} ${reviewstyles.marginBtn}`}>
                        <label htmlFor="" className={reviewstyles.paddingLabel}>Full Name</label>
                        <input type="text" name="" id="" className={reviewstyles.fullWidth} style={{border: "1px solid #C1C1C1"}} />
                    </div>
                    <div className={`${reviewstyles.widthDiv2} ${reviewstyles.alignBtn} ${reviewstyles.marginBtn}`}>
                        <p className={reviewstyles.paddingLabel}>Avatar</p>
                        <section style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                            <div style={{width:"77px", height:"77px", borderRadius:"50%", backgroundColor:"GrayText"}}>
                            </div>
                            <label htmlFor="reviewavatar" className={reviewstyles.fileLabel}>Choose File</label>
                            <input type="file" name="" id="reviewavatar" className={reviewstyles.fileInput} />
                        </section>
                    </div>
                    <div className={`${reviewstyles.widthDiv2} ${reviewstyles.alignBtn} ${reviewstyles.marginBtn}`}>
                        <input type="radio" name="testifyReview" id="testifybtn" style={{marginRight:"5px"}} onClick={handleTestimony} />
                        <label htmlFor="testifybtn" style={{marginRight:"15px"}}>Testify</label>
                        <input type="radio" name="testifyReview" id="reviewbtn" style={{marginRight:"5px"}} onClick={handleReview} />
                        <label htmlFor="reviewbtn">Give a review</label>

                        <Testy tes={writeTestimony} />
                        <Rev tesRev={writeReview} />
                    </div>
                    </div>
            </section>
        </div>
    )
}

export default Review
