import React from 'react'
import Footer from './Footer'
import GeneralHeader1 from './GeneralHeader1'
import checkstyles from './checkout.module.css'
function ExamCheckOut() {
    return (
        <div>
            <GeneralHeader1 />
            <main className={checkstyles.checkAppContainer}>
                <div className={checkstyles.checkAppDiv}>
                <section className={checkstyles.checkAppSection1}>
                    <h4 className={checkstyles.checkAppH4}>Checkout Application</h4>
                    <table className={checkstyles.checkAppTable}>
                        <tbody>
                            <tr style={{borderBottom:"1px solid rgba(30, 25, 61, 0.2)"}}>
                                <td style={{padding:"30px 0", fontWeight:500, fontSize:"18px", lineHeight:"27px"}}>SAT class</td>
                                <td style={{padding:"30px 0", fontWeight:500, fontSize:"18px", lineHeight:"27px"}}>NGN 10,000</td>
                            </tr>
                            <tr>
                                <td style={{padding:"30px 0", fontWeight:500, fontSize:"18px", lineHeight:"27px"}}>IELTS class</td>
                                <td style={{padding:"30px 0", fontWeight:500, fontSize:"18px", lineHeight:"27px"}}>NGN 10,000</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <section className={checkstyles.checkAppSection2}>
                    <h4 className={checkstyles.checkAppH4}>Payment</h4>
                    <table className={checkstyles.checkAppTable2}>
                        <tbody>
                            <tr style={{borderBottom:"1px solid rgba(30, 25, 61, 0.2)"}}>
                                <td style={{padding:"30px 0"}}>Classes Fee:</td>
                                <td style={{padding:"30px 0", fontWeight:500, fontSize:"18px", lineHeight:"27px"}}>NGN 20,000</td>
                            </tr>
                            <tr>
                                <td style={{padding:"30px 0"}}>Total:</td>
                                <td style={{padding:"30px 0", fontWeight:500, fontSize:"18px", lineHeight:"27px"}}>NGN 10,000</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className={checkstyles.inputDiv}>
                        <input type="text" name="" id="" placeholder="Enter your coupon code" className={`${checkstyles.checkOutInput} ${checkstyles.input}`} />
                        <button className={`${checkstyles.checkOutInput} ${checkstyles.checkbtn1}`}>APPLY</button>
                    </div>
                    <button className={checkstyles.checkbtn2}>CHECKOUT</button>
                    <p className={checkstyles.checkOutP}>BACK TO APPLICATION PAGE</p>
                </section>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default ExamCheckOut
