import React from 'react'
import reviewstyles from './SearchReview.module.css'
import './rating.css'

function Testy(props) {
    return (
        <div  className={props.tes ? 'rateNone' : 'rateBlock'}>
            <div className={`${reviewstyles.widthDiv2} ${reviewstyles.marginBtn}`}>
                <label htmlFor="" className={`${reviewstyles.fullWidth} ${reviewstyles.paddingLabel}`} style={{marginBottom:"5px"}}>Write review</label>
                <textarea name="" id="" cols="30" rows="5" className={reviewstyles.fullTextArea} style={{border: "1px solid #C1C1C1"}}></textarea>
            </div>
            <button className={reviewstyles.subBtn}>SEND</button>

        </div>
    )
}

export default Testy
