import React from 'react'
import AuthSignInS from './AuthSignInS'

function AuthSignInSError() {
    return (
        <AuthSignInS display="block" border="1px solid #F06C6E"/>
    )
}

export default AuthSignInSError
