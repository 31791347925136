import React from 'react'
import GeneralHeader from './GeneralHeader'
import SDashboardSide from './SDashboardSide'

function SDashboardTutor() {
    return (
        <div>
            <GeneralHeader />
            <main>
            <SDashboardSide />
            <form action="">
                <label htmlFor="">Exam</label>
                <select name="" id="">Exam
                    <option value="sat">sat</option>
                </select>
                <label htmlFor="">Exam type</label>
                <select name="" id="">select exam type
                    <option value="sat">sat</option>
                </select>
                <label htmlFor="">Preferred tutor gender</label>
                <select name="" id="">Select tutor gender
                    <option value="sat">sat</option>
                </select>
                <button type="submit">REQUEST TUTOR</button>
            </form>
            </main>
        </div>
    )
}

export default SDashboardTutor
