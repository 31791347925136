import React from 'react'
import RatingStars from './RatingStars'
import reviewstyles from './SearchReview.module.css'

function Rev(props) {
    return (
        <div className={props.tesRev ? "rateNone" : "rateBlock"}>
            <div className={`${reviewstyles.widthDiv2} ${reviewstyles.marginBtn}`}>
                <label htmlFor="" className={`${reviewstyles.fullWidth} ${reviewstyles.paddingLabel}`} style={{marginBottom:"5px"}}>Write review</label>
                <textarea name="" id="" cols="30" rows="5" className={reviewstyles.fullTextArea} style={{border: "1px solid #C1C1C1"}}></textarea>
            </div>
            <div className={`${reviewstyles.widthRate} ${reviewstyles.marginBtn}`}>
                <RatingStars />
            </div>
            <button className={reviewstyles.subBtn}>SEND</button>
        </div>
    )
}

export default Rev
