import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "../../shared/SectionTitle";
import illustration from "../../images/tutorsignup.svg";
import PrimaryButton from "../../shared/PrimaryButton";
import "./BecomeATutor.css";

export default function BecomeATutor() {
  return(
    <section className="becomeatutor-wrapper">
      <Container>
        <SectionTitle title="Become a Tutor" className="pb-0" />
        <Row>
          <Col md={6}>
            <div>
              <img src={illustration} className="img-fluid" alt="tutor illustration" />
            </div>
          </Col>
          <Col md={6}>
            <div className="offers pt-5 mt-5">
              <p className="mb-5 pb-3">FNG offers qualified tutors who have a proven track records of students making the highest scores in all external exams like SAT, TOEFL, IELTS, YOS, GMAT, GRE etc in the world. vFNG works without cease for all students to live their purpose, to study abroad, work and invest in our partner countries. FNG is built on education empowerment.</p>
              <PrimaryButton value="Apply" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}