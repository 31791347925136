import React from 'react'
import examstyle from './Exam.module.css'
import homestyle from './AboutHome.module.css'
import studystyle from './Study.module.css'
import { Link } from 'react-router-dom'
function StudyAbroadHeader(props) {
    return (
        <section className={`${homestyle.firstSection} ${examstyle.firstSection} ${studystyle.firstSection}`} style={props.opacity}>
        <div className={`${homestyle.firstSectionDiv} ${studystyle.abroadImage}`}>
            <div className={studystyle.abroadTextContainer}>
             <h4 className={examstyle.firstSectionDivH4}>Study Abroad</h4>
             <p className={examstyle.firstSectionDivP}>Admission is guaranteed</p>
             <button className={`${homestyle.firstSectionDivButton} ${examstyle.firstSectionDivButton}`}>
                <Link to="/study/abroad/usa" style={{color:"#fff", textDecoration:"none"}}>APPLY NOW</Link>
            </button>
            </div>
        </div>
        </section> 
)
}

export default StudyAbroadHeader
