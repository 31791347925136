import React from 'react'
import GeneralHeader1 from './GeneralHeader1'
import StudyAbroadHeader from './StudyAbroadHeader'
import StudySide from './StudySide'
import studystyle from './Study.module.css'
import StudyBottom from './StudyBottom'
import Footer from './Footer'
import StudyPI from './StudyPI'

function StudycanadaPI() {
    const faint = {
        opacity:0.3,
        backgroundColor:"rgba(0, 0, 0, 0.4)"
    }

    return (
        <div>
            <GeneralHeader1 />
            <StudyAbroadHeader opacity={faint} />
            <main className={studystyle.countryPage} style={faint}>
                <StudySide />
                <section className={studystyle.pageSide2}>
                    <p className={studystyle.pageP}>Canada consistently ranks as one of the best countries in the world—and is currently the #1 best country for quality of life. Study in Canada, and you’ll receive an internationally recognized education from some of the top educators and academics in the world. Whether you choose to study in one of our large, vibrant cities or settle on a small campus in a warm, welcoming community, your experience will be one that will shape your life. It may lead to a career and a future in Canada, or better career prospects at home. At the very least, it will give you access to our four beautiful seasons, wide-open spaces, abundant wildlife, multicultural diversity, clean environment and incredible quality of life.</p>
                    <ul className={studystyle.listContainer}>
                        <h4 className={studystyle.pageSideH4}>Why Choosing to Study in Canada?</h4>
                        <p className={studystyle.pageP}>
                        For decades, we’ve welcomed international students from around the globe. Through them, we’ve discovered there’s plenty to love about Canada. Here are some of the many benefits of making Canada your education destination:
                        </p>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>You’ll learn with the best and brightest</h5>
                            <p className={studystyle.pageP}>
                            Canada is recognized worldwide for our outstanding quality of education—from elementary school to post-secondary studies. Our educators are highly trained and bring diverse perspectives to the classroom. At the university level, 2 out of every 5 academics hold at least 1 international degree. And we have 10 of the top 250 universities worldwide[2]. In our opinion, there’s no better place to learn! Need more convincing? Watch our student stories.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>We’ll welcome you with open arms</h5>
                            <p className={studystyle.pageP}>
                            We’re proud to say that the ‘warm and friendly’ stereotype about Canadians is true. When you come to Canada, you’ll find a welcoming country with a unique Canadian culture that embraces diversity. From urban centres to small towns, Canada is considered one of the safest countries in the world for international students.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>Our quality of life ranks highest in the world</h5>
                            <p className={studystyle.pageP}>
                            If you’re looking for the high life, we have it. Canadians and international students in Canada enjoy a high standard of living. It’s a great place to live and work. In fact, we rank #1 in the world when it comes to quality of life. According to the World University Rankings, we also have 4 of the top 100 best student cities in the world[3]. Now that’s something to write home about!
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>We’ll set you on the path to a promising future</h5>
                            <p className={studystyle.pageP}>
                            We like to study too. In fact, we’ve researched graduate outcomes for our students—both domestic and international—for years. And you know what we’ve learned? Canadian college and university students graduate with a strong earning potential. That’s because we position them (and you) for a successful future and rewarding careers. In the last decade alone, Canada created 1.6 million new jobs for graduates[4].
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>The cost to study and live here is affordable</h5>
                            <p className={studystyle.pageP}>
                            Canada is known for having some of the lowest university tuition fees among English-speaking countries[5]. Whatever your budget may be, you’ll find something just right for you in our diverse range of education options. Research the cost of post-secondary programs and living expenses in the city or town of your choice so you can prepare your budget.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <h5 className={studystyle.pageSideH5}>We’ll offer you an experience like no other</h5>
                            <p className={studystyle.pageP}>
                            Adventure awaits you in Canada! One of the greatest things about studying in Canada is the opportunity to experience a climate, culture and way of life that is beyond compare. From seeing the vibrant colours of our autumn leaves to the soft sounds of snowshoeing through the forest, each season and place has so much to offer. Come experience Canada and find out why there’s so much to love about us.                            
                            </p>
                        </li>
                    </ul>
                    <StudyBottom />
                </section>
            </main>
            <Footer />
            <StudyPI name="canada" location="/study/abroad/canada/eb" />
        </div>
    )
}

export default StudycanadaPI
