import React from 'react'
import GeneralHeader1 from './GeneralHeader1'
import examstyle from './Exam.module.css'
import studystyle from './Study.module.css'
import Footer from './Footer'
import StudyAbroadHeader from './StudyAbroadHeader'
import {ReactComponent as Usaweb} from './images/usaweb.svg';
import {ReactComponent as Usamobile} from './images/usamobile.svg';
import {ReactComponent as Turkeyweb} from './images/turkeyweb.svg';
import {ReactComponent as Turkeymobile} from './images/turkeymobile.svg';
import {ReactComponent as Canadaweb} from './images/canadaweb.svg';
import {ReactComponent as Canadamobile} from './images/canadamobile.svg';
import {ReactComponent as Dubaiweb} from './images/dubaiweb.svg';
import {ReactComponent as Dubaimobile} from './images/dubaimobile.svg';
import { Link } from 'react-router-dom'
function StudyAbroad() {
    return (
        <div>
            <GeneralHeader1 />
            <StudyAbroadHeader />
           <main className={studystyle.mainContainer}>
               <div className={studystyle.studyWidth}>
                <p className={`${examstyle.mainP} ${studystyle.mainP}`}>
                First New Generation Citadel Tracklane is your fast path to academic excellence. Every year, students from Nigeria enter well thought of programs at our hand-selected partner Universities in United States, Turkey, Canada and Dubai. 
                <br />
                Your admission is guaranteed when you apply through us.  We take pleasure in assisting prospective students from at every stage such as admission to visa processing and documentation and as well as flight reservation stages.
                </p>
                <h5 className={`${examstyle.mainListLiH5} ${studystyle.mainListLiH5}`}>COUNTRIES</h5>
                <section className={`${studystyle.countryBox} ${studystyle.countryBox3}`}>
                    <div style={{padding:"10px 10px"}} className={studystyle.country}>
                    <Link to="/study/abroad/usa" style={{textDecoration:"none"}} className={studystyle.country}>                    
                        <h4>UNITED STATE OF AMERICA</h4>
                        <p>World’s largest international student population, with more than 1,000,000 students choosing to broaden their education and life experience</p>
                    </Link>
                    </div>
                    <div className={`${studystyle.imageContainer} ${studystyle.collabBoxUSA}`}>
                        <Usaweb className={studystyle.webDisplay} />
                        <Usamobile className={studystyle.mobileDisplay} />
                    </div>
                </section>
                <section className={studystyle.countryBox}>
                    <div style={{padding:"10px 10px"}} className={studystyle.country}>
                    <Link to="/study/abroad/turkey" style={{textDecoration:"none"}} className={studystyle.country}>                    
                        <h4>TURKEY</h4>
                        <p>Turkey is fastly attaining a reputation as an ideal destination for international students who are looking for high quality of education.</p>
                    </Link>
                    </div>
                    <div className={`${studystyle.imageContainer} ${studystyle.collabBoxTurkey}`}>
                        <Turkeyweb className={studystyle.webDisplay} />
                        <Turkeymobile className={studystyle.mobileDisplay} />
                    </div>
                </section>
                <section className={studystyle.countryBox}>
                    <div style={{padding:"10px 10px"}} className={studystyle.country}>
                    <Link to="/study/abroad/canada" style={{textDecoration:"none"}} className={studystyle.country}>                    
                        <h4>CANADA</h4>
                        <p>Canada boasts 96 universities scattered across its urban and regional areas, most of which are very welcoming to international students.</p>
                    </Link>
                    </div>
                    <div className={`${studystyle.imageContainer} ${studystyle.collabBoxCanada}`}>
                        <Canadaweb className={studystyle.webDisplay} />
                        <Canadamobile className={studystyle.mobileDisplay} />
                    </div>
                </section>
                <section className={`${studystyle.countryBox} ${studystyle.countryBox2}`}>
                    <div style={{padding:"10px 10px"}} className={studystyle.country}>
                    <Link to="/study/abroad/dubai" style={{textDecoration:"none"}} className={studystyle.country}>                    
                        <h4>DUBAI</h4>
                        <p>Education in Dubai has been rated one of the best in whole world.</p>
                    </Link>
                    </div>
                    <div className={`${studystyle.imageContainer} ${studystyle.collabBoxDubai}`}>
                        <Dubaiweb className={studystyle.webDisplay} />
                        <Dubaimobile className={studystyle.mobileDisplay} />
                    </div>
                </section>
                </div>
           </main>
           <Footer />
        </div>
    )
}

export default StudyAbroad
