import React from 'react'
import Footer from './Footer'
import aboutstyles from './AboutHome.module.css'
import GeneralHeader1 from './GeneralHeader1'
import contactstyles from './checkout.module.css'
function Contact() {
    return (
        <div>
            <GeneralHeader1 />
            <main>
            <section className={`${aboutstyles.firstSection} ${contactstyles.contactFirstSection}`}>
                <section className={aboutstyles.firstSectionDiv}>
                <div className={aboutstyles.firstASecDiv}>
                    <h1 className={`${aboutstyles.firstSectionDivP} ${aboutstyles.firstASecDivH1}`}>Get in Touch</h1>
                    <p className={aboutstyles.firstASecDivP}>Reach out to us for consultations, enquiry, partnership or any concern. We will be happy to hear from you.</p>
                </div>
                </section>
            </section>
                <section className={contactstyles.contactSection}>
                    <div className={contactstyles.contactDiv}>
                        <p className={contactstyles.contactP}>Send a Message <span></span></p>
                        <form action="" className={contactstyles.contactForm}>
                            <section style={{display:'flex', justifyContent:'space-between', alignItems:'center', flexWrap:'wrap'}}>
                                <div className={contactstyles.formGroup1}>
                                    <label htmlFor=""></label>
                                    <input type="text" name="" id="" placeholder="First name" className={`${contactstyles.inputGroup1} ${contactstyles.contactInput}`} />
                                </div>
                                <div className={contactstyles.formGroup1}>
                                    <label htmlFor=""></label>
                                    <input type="text" name="" id="" placeholder="Last name" className={`${contactstyles.inputGroup1} ${contactstyles.contactInput}`} />
                                </div>
                                <div className={contactstyles.formGroup1}>
                                    <label htmlFor=""></label>
                                    <input type="tel" name="" id="" placeholder="Phone number" className={`${contactstyles.inputGroup1} ${contactstyles.contactInput}`} />
                                </div>
                                <div className={contactstyles.formGroup1}>
                                    <label htmlFor=""></label>
                                    <input type="email" name="" id="" placeholder="email" className={`${contactstyles.inputGroup1} ${contactstyles.contactInput}`} />
                                </div>
                            </section>
                            <div className={`${contactstyles.formGroup1} ${contactstyles.formGroup2}`}>
                                <label htmlFor=""></label>
                                <input type="text" name="" id="" placeholder="Message subject" className={`${contactstyles.inputGroup1} ${contactstyles.inputGroup2} ${contactstyles.contactInput}`} />
                            </div>

                            <div className={`${contactstyles.formGroup1} ${contactstyles.formGroup2}`}>
                                <label htmlFor=""></label>
                                <textarea name="" id="" cols="30" rows="10" placeholder="Message" className={`${contactstyles.inputGroup1} ${contactstyles.inputGroup2} ${contactstyles.contactInput} ${contactstyles.inputGroup3}`}></textarea>
                            </div>
                            <button className={contactstyles.contactBtn}>SEND</button>
                        </form>
                        </div>
                </section>
            </main>

            <Footer />
        </div>
    )
}

export default Contact
