import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "../../shared/SectionTitle";
import "./WhyChooseUs.css";

const reasons = [
  {
    id: 1,
    number: "01",
    title: "Admissions and Scholarships",
    value: "All our students get their admissions to study abroad through FIRST NEW GENERATION CITADEL."
  },
  {
    id: 2,
    number: "02",
    title: "Free Consultations",
    value: "Our students get free counseling before going to the embassy for Visa application."
  },
  {
    id: 3,
    number: "03",
    title: "Free Study Packs and Online Courses",
    value: "Students have access to study materials and online courses and training which you can learn at your own pace."
  },
  {
    id: 4,
    number: "04",
    title: "Pre-departure Services",
    value: "Students are lectured on life outside the Country and gets airport pick to help them with orientation to the new country."
  }
]

export default function WhyChooseUs() {
  return(
    <section className="whychooseus-wrapper">
      <Container>
        <SectionTitle title="Why Choose Us" />
        <Row>
          {
            reasons.map(reason => (
              <Col md={6}>
                <div className="d-flex mb-5">
                  <div className="mr-3">
                    <p className="reason-number">{reason.number}</p>
                  </div>
                  <div>
                    <p className="reason-title">{reason.title}</p>
                    <p className="reason-value">{reason.value}</p>
                  </div>
                </div>
              </Col>
            ))
          }
        </Row>
      </Container>
    </section>
  );
}
