import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SectionTitle from "../../shared/SectionTitle";
import "./ServicesSection.css";
import exam from "../../images/exam.svg";
import abroad from "../../images/abroad.svg";
import tourism from "../../images/tourism.svg";
import language from "../../images/newlanguage.svg";
import adult from "../../images/adult.svg";
import { Link } from "react-router-dom";

const services = [
  {
    id: 1,
    url: "exam",
    img: exam,
    alt: "exam",
    title: "Examination Preparations and Registration",
    value: "We handle preparations and registration of all forms of exams, ranging from English proficiency examinations like IELTS etc to holidays classes"
  },
  {
    id: 2,
    url: "study/abroad",
    img: abroad,
    alt: "study abroad",
    title: "Study abroad",
    value: "We can help you make you study abroad dreams come true from the application stage to the flight to your preferred country of study"
  },
  {
    id: 3,
    url: "error",
    img: tourism,
    alt: "tourism",
    title: "Tourism",
    value: "We also help our candidates families with relocation free visa counseling, startup platforms and citizenship by investment, etc."
  },
  {
    id: 4,
    url: "error",
    img: language,
    alt: "language",
    title: "Learn a new language",
    value: "We handle preparations for all forms of exams, ranging from English proficiency examinations like IELTS etc to holidays classes"
  },
  {
    id: 5,
    url: "error",
    img: adult,
    alt: "adult literacy",
    title: "Adult Literacy",
    value: "FNG has decided to open it's doors to adults who wish to learn basics of reading, writing and simple arithmetics."
  }
]

export default function ServicesSection() {
  return(
    <section className="services-section">
      <Container fluid>
        <SectionTitle title="Our Services" />
        <Row className="justify-content-center">
        {
          services.map((service) => (
            <Col md={4}>
              <Link to={service.url} className="service-wrapper mb-3">
                <div key={service.id}>
                  <img 
                    src={service.img} 
                    className="img-fluid mb-3" 
                    alt={service.alt} 
                  />
                  <h6>{service.title}</h6>
                  <p>{service.value}</p>
                </div>
              </Link>
            </Col>
          ))
        }
        </Row>
      </Container>
    </section>
  );
}
