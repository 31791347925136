import React from 'react'
import Footer from './Footer'
import GeneralHeader1 from './GeneralHeader1'
import Guaranteed from './Guaranteed'
import Progress from './Progress'
import ProgressReal from './ProgressReal'
import './gallery.css'
import styles from './AboutHome.module.css'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import {ReactComponent as TestimonyIcon} from './images/genimage.svg';
// import { ReactComponent as Logo } from './images/Homepage.svg';
import HeroSection from "./Home/HeroSection/HeroSection";
import ServicesSection from './Home/ServicesSection/ServicesSection'
import WhyChooseUs from './Home/WhyChooseUs/WhyChooseUs'
import BecomeATutor from './Home/BecomeATutor/BecomeATutor'
function Home() {
    const slide = {
        dots: true,
        arrows: false,
        centerPadding: '40px',
        centerMode: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        pauseOnDotsHover:true,
        pauseOnFocus:true,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1,
              }
            },
            {
              breakpoint: 600,
              settings: {
                arrows: false,
                centerMode: false,
                centerPadding: '5px',
                slidesToShow: 1,
                slidesToScroll: 1
                
              }
            },
          ]

    }
    return (
        <div>
            <GeneralHeader1 />
            <HeroSection />
            <ServicesSection />
            <WhyChooseUs />
            <main className={styles.mainHome}>
                <BecomeATutor />
                <div className={styles.genWidth}>
                <Link to="/testimonials" style={{color:"#313059", textDecoration:"none"}}>
                <section className={styles.sectionTestimony}>
                <h4 className={`${styles.h4} ${styles.h4Rule2} ${styles.testimonyH4}`}>Testimonies</h4>
                <div className={styles.allListContainer}>
                        <Slider {...slide}>
                        <div className={`${styles.testimonyUlLi} ${styles.slickList}`}>
                            <section className={styles.testimonyImageContainer}>
                                <div className={styles.testimonyImage}>
                                        <TestimonyIcon />
                                </div>
                                <p className={styles.testimonyCapP}>Oluwaseun Owoniyi
                                <p className={styles.testimonyCapSpan}>M.D</p>
                                </p>
                           </section>
                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nos</p>
                       </div>
                        <div className={`${styles.testimonyUlLi} ${styles.slickList}`}>
                            <section className={styles.testimonyImageContainer}>
                                <div className={styles.testimonyImage}>
                                        <TestimonyIcon />
                                </div>
                                <p className={styles.testimonyCapP}>Oluwaseun Owoniyi
                                <p className={styles.testimonyCapSpan}>M.D</p>
                                </p>
                            </section>
                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nos</p>
                       </div>
                        <div className={`${styles.testimonyUlLi} ${styles.slickList}`}>
                            <section className={styles.testimonyImageContainer}>
                                <div className={styles.testimonyImage}>
                                        <TestimonyIcon />
                                </div>
                                <p className={styles.testimonyCapP}>Oluwaseun Owoniyi
                                <p className={styles.testimonyCapSpan}>M.D</p>
                                </p>
                           </section>
                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nos</p>
                       </div>
                       <div className={`${styles.testimonyUlLi} ${styles.slickList}`}>
                            <section className={styles.testimonyImageContainer}>
                                <div className={styles.testimonyImage}>
                                        <TestimonyIcon />
                                </div>
                                <p className={styles.testimonyCapP}>Oluwaseun Owoniyi
                                <p className={styles.testimonyCapSpan}>M.D</p>
                                </p>
                           </section>
                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nos</p>
                       </div>
                       <div className={`${styles.testimonyUlLi} ${styles.slickList}`}>
                            <section className={styles.testimonyImageContainer}>
                                <div className={styles.testimonyImage}>
                                        <TestimonyIcon />
                                </div>
                                <p className={styles.testimonyCapP}>Oluwaseun Owoniyi
                                <p className={styles.testimonyCapSpan}>M.D</p>
                                </p>
                           </section>
                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nos</p>
                       </div>
                       <div className={`${styles.testimonyUlLi} ${styles.slickList}`}>
                            <section className={styles.testimonyImageContainer}>
                                <div className={styles.testimonyImage}>
                                        <TestimonyIcon />
                                </div>
                                <p className={styles.testimonyCapP}>Oluwaseun Owoniyi
                                <p className={styles.testimonyCapSpan}>M.D</p>
                                </p>
                           </section>
                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nos</p>
                       </div>
                       <div className={`${styles.testimonyUlLi} ${styles.slickList}`}>
                            <section className={styles.testimonyImageContainer}>
                                <div className={styles.testimonyImage}>
                                        <TestimonyIcon />
                                </div>
                                <p className={styles.testimonyCapP}>Oluwaseun Owoniyi
                                <p className={styles.testimonyCapSpan}>M.D</p>
                                </p>
                           </section>
                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nos</p>
                       </div>

                        </Slider>
                    {/* </ul> */}
                    </div>
                </section>
                </Link>
                </div>
            </main>
            <Guaranteed />
            <Progress />
            <ProgressReal />
            <section className={styles.SubscribeContainer}>
                <div className={styles.sectionSubscribe}>
                    <div className={styles.SubText}>
                        <span className={`${styles.emailIcon} ${styles.testimonyCapSpan}`}></span>
                        <div>
                        <p className={styles.SubscribeP}>
                            Unlock Special Study and Tourism Deals 
                        </p>
                        <span className={`${styles.testimonyCapSpan} ${styles.SubscribeSpan}`}>Enter your email to receive top deals first</span>
                        </div>
                    </div>
                <form>
                    <label htmlFor=""></label>
                    <input type="text" placeholder="Enter your email" className={styles.SubInput} />
                    <button className={styles.SubBtn}>SUBSCRIBE</button>
                </form>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Home
