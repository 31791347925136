import React from 'react'
import styles from './footer.module.css'
function Progress() {
    return (
        <section className={styles.progressContainer}>
        <div className={styles.progress}>
            <p className={styles.progressFirstP}>AFFORDABLE,  EXPERT Teacher, Safe &#38; Secure Admission / Scholarships</p>
            <h1 className={styles.progressFirstH1}>BOOST TEST <br />SCORES</h1>
            <div className={styles.progressDiv}></div>
            <p className={styles.progressLastP}>Everywhere in the world FNG will customizable program that let’s you BUILD you</p>
        </div>
        </section>
    )
}

export default Progress
