import React from 'react'
import Footer from './Footer'
import GeneralHeader1 from './GeneralHeader1'
import studystyle from './Study.module.css'
import StudyAbroadHeader from './StudyAbroadHeader'
import StudyBottom from './StudyBottom'
import StudyEI from './StudyEI'
import StudySide from './StudySide'

function StudydubaiEI() {
    const faint = {
        opacity:0.3,
        backgroundColor:"rgba(0, 0, 0, 0.4)"
    }

    return (
        <div>
           <GeneralHeader1 />
           <StudyAbroadHeader opacity={faint} />
           <main className={studystyle.countryPage} style={faint}>
                <StudySide />
                <section className={studystyle.pageSide2}>
                    <p className={studystyle.pageP}>
                        Dubai is also known as the “CITY OF GOLD.” It is one of the largest countries in the United Arab Emirates. It is rich in economy, and a major part of Dubai’s economy deals in gold trade. Dubai is known as the Education Hub as it provides every facility in terms of education to local and international students. Here, Universities and Colleges have a true commitment towards education. The campus atmosphere motivates students to learn and explore as students worldwide come here for higher education to interact with the multinational student community. Many campuses in Dubai offer education to International Students, providing them courses like Science, Engineering and Technology, Architecture, Aviation, Management, Business, Economics, Media and Mass, Computers and IT, Law, Natural Sciences etc. These programs further give more work opportunities. At the end of the program, students get a recognized degree by the Ministry of Higher Education in UAE. Their degrees are highly reputed and globally accepted.
                    </p>
                    <ul className={studystyle.listContainer}>
                        <h4 className={studystyle.pageSideH4}>Why Choosing to Study in Dubai?</h4>
                        <li className={studystyle.list}>
                            <p className={studystyle.pageP}>
                            Apart from glamour, Dubai is known for its excellence in academics. In recent times, Universities have been attracting students all over the world. In the coming years, many new projects are being launched in the fields of fashion designing. It would bring a drastic rise in job opportunities in these fields. So, it is recommended for students who have an interest in designing to come here and explore more. Here students have many choices in programs. There is huge scope in building construction and interior designing as well. Universities very well prepare students for the professional world that they will be entering after their studies. Following are the reasons why students should study in Dubai:
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <p className={studystyle.pageP}>
                            The UAE has a well established Education System.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <p className={studystyle.pageP}>
                            Universities in Dubai have modern infrastructure.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <p className={studystyle.pageP}>
                            Dubai is crime-free, which ensures the safety of students.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <p className={studystyle.pageP}>
                            Tuition fees and living costs are less than other countries like the United Kingdom, the United States, European Countries etc.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <p className={studystyle.pageP}>
                            It is considered one of the fastest-growing economies. So, leading to more future opportunities. Students can dream of starting a job after their studies.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <p className={studystyle.pageP}>
                            Since students worldwide come here for higher education, they experience a multicultural environment that is very valuable across the industry sector.
                            </p>
                        </li>
                        <li className={studystyle.list}>
                            <p className={studystyle.pageP}>
                            There are possibilities in finding full-time employment due to vast development in Trades, Commerce, and Infrastructure.                            </p>
                        </li>

                    </ul>
                    <StudyBottom />
                </section>
            </main> 
            <Footer />
            <StudyEI name="dubai" location="/study/abroad/usa/sum" />
        </div>
    )
}

export default StudydubaiEI
