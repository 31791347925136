import React, {useState} from 'react'
import Footer from './Footer'
import GeneralHeader1 from './GeneralHeader1'
import searchstyles from './faq.module.css'
import {ReactComponent as FilterImage} from './images/filter.svg';
import SearchFilter from './SearchFilter';
import textData from "./SearchParams.json";

function Search() {
    const [isOff, setIsOff] = useState(false)
    const handleToggle = (e) => {
        if (window.innerWidth <= 767) {
            setIsOff(e.target.checked)
        }else{
            setIsOff(false)
        }
    }
    const [searchTerm, setSearchTerm] = useState('')
    const handleSearch = (e) => {
        return setSearchTerm(e.target.value)
    }
    function filterFunc(value) {
        if (searchTerm === "") {
            return value
        }else if (value.topics.toLowerCase().includes(searchTerm.toLowerCase())){
            return value
        }
    }
    function mapFunc(value){
        return <div className={searchstyles.divFilterText}>
                    <h5 className={searchstyles.filterH5}>{value.topics}</h5>
                    <p className={searchstyles.filterTopicText}>
                    {/* {window.btoa(value)} */}

                    {value.body}
                    </p>
        </div>
    }
    // byte[] utf8  = htmlMessage.getBytes("UTF8");
    // htmlMessage= new String(new Base64().encode(utf8));
    return (
        <div>
            <GeneralHeader1 />
            <main className={searchstyles.searchContainer}>
                <section className={searchstyles.searchPoint}>
                    {/* large screen filter */}
                    <SearchFilter show="desktopNav" toggle={!isOff} />
                    <section className={searchstyles.mainFilterText}>
                        <section className={searchstyles.filterSec}>

                            <div className={searchstyles.filterText}>
                                <p className={searchstyles.searchTopP2}>
                                    10 results found for:
                                </p>
                                
                                <p className={searchstyles.filteredResult}>
                                    Study Abroad
                                </p>
                                <label htmlFor="" className={searchstyles.labelStyle2}>
                                    <input type="text" name="search" id="" className={searchstyles.inputStyle} onChange={handleSearch} />
                                </label>
                            </div>
                            <div className={searchstyles.phoneFilter}>
                                <label htmlFor="" className={searchstyles.labelStyle}>
                                    <input type="text" name="search" id="" className={searchstyles.inputStyle} onChange={handleSearch} />
                                </label>
                                <label htmlFor="togglefilter">
                                <input type="checkbox" name="" id="togglefilter" onClick={handleToggle} style={{visibility: "hidden"}} />
                                <FilterImage />
                                </label>
                            </div>
                            <SearchFilter show="phoneNav" space="10px" toggle={isOff} />

                        </section>
                        {textData.filter(filterFunc).map(mapFunc)}
                    </section>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default Search
