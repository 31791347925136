import React from 'react'
import studystyle from './StudyMisc.module.css'
import StudyQueHeader from './StudyQueHeader'
import { Link } from 'react-router-dom'
function StudyPI(props) {
    const colorChange = {
        color: "#3C3B6E",
        padding: "8px 4px",
        borderBottom: "3px solid #3C3B6E",
        fontWeight: "bold"
    }
    return (
        <div className={studystyle.formContainer} style={props.queDisplay} id={props.pilocation}>
            <StudyQueHeader PI={colorChange} />
            <form action="" className={studystyle.form}>
                <div className={studystyle.PIFormGroup1}>
                    <label htmlFor="" className={studystyle.PILabel}>
                        <input type="text" name={`${props.name}FirstName`} id={`${props.name}FirstName`} placeholder="First name*" className={studystyle.PIInput} />
                    </label>
                    <label htmlFor="" className={studystyle.PILabel}>
                        <input type="text" name={`${props.name}LastName`} id={`${props.name}LastName`} placeholder="Last name*" className={studystyle.PIInput} />
                    </label>
                    <label htmlFor="" className={studystyle.PILabel}>
                        <input type="text" name={`${props.name}Country`} id={`${props.name}Country`} placeholder="Country*" className={studystyle.PIInput} />
                    </label>
                    <label htmlFor="" className={studystyle.PILabel}>
                        <input type="text" name={`${props.name}State`} id={`${props.name}State`} placeholder="State*" className={studystyle.PIInput} />
                    </label>
                    <label htmlFor="" className={`${studystyle.PILabel} ${studystyle.label2}`}>
                        <input type="text" name={`${props.name}Code`} id={`${props.name}Code`} placeholder="+234" className={studystyle.inputPlus} />
                        <input type="tel" name={`${props.name}PhoneNumber`} id={`${props.name}PhoneNumber`} className={studystyle.inputTel} />
                    </label>
                    <label htmlFor="" className={studystyle.PILabel}>
                        <input type="email" name={`${props.name}Email`} id={`${props.name}Email`} placeholder="Email*" className={studystyle.PIInput} />
                    </label>
                </div>
                <div className={`${studystyle.PIFormGroup1} ${studystyle.labelGroup}`}>
                    <label htmlFor="" className={studystyle.PILabel1}>
                        <input type="date" name={`${props.name}DOB`} id={`${props.name}DOB`} placeholder="Date of Birth" className={studystyle.PIInput} />
                    </label>
                    <label htmlFor="" className={studystyle.PILabel1}>
                        <select name={`${props.name}Gender`} id={`${props.name}Gender`}  className={studystyle.PIInput}>
                            <option value="">Gender</option>
                        </select>
                    </label>
                    <label htmlFor="" className={studystyle.PILabel1}>
                        <select name={`${props.name}MaritalStatus`} id={`${props.name}MaritalStatus`}  className={studystyle.PIInput}>
                            <option value="">Marital status</option>
                        </select>
                    </label>
                </div>
                <div className={`${studystyle.PIFormGroup1} ${studystyle.labelGroup}`}>
                    <label htmlFor="" className={studystyle.PILabel2}>
                        <input type="text" name={`${props.name}Address`} id={`${props.name}Address`} className={studystyle.PIInput} placeholder="Street, City, State, or Providence" />
                    </label>
                </div>
                <button className={studystyle.btn}>
                    <Link to={props.location} style={{color:"#fff", textDecoration:"none"}}>NEXT</Link>                    
                </button>
            </form>
        </div>
    )
}

export default StudyPI
