import React, {useState} from 'react'
import Footer from './Footer'
import GeneralHeader1 from './GeneralHeader1'
import faqstyles from './faq.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FAQs() {
    const [toeflCost, setToeflCost] = useState(false)
    const [ieltsCost, setIeltsCost] = useState(false)
    const [greCost, setGreCost] = useState(false)
    const [satCost, setSatCost] = useState(false)
    const [gmatCost, setGmatCost] = useState(false)

    const [toefl, setToefl] = useState(false)
    const [ielts, setIelts] = useState(false)
    const [gre, setGre] = useState(false)
    const [sat, setSat] = useState(false)
    const [gmat, setGmat] = useState(false)

    const [downToeflCost, setDownToeflCost] = useState(true)
    const [upToeflCost, setUpToeflCost] = useState(true)
    const [downToefl, setDownToefl] = useState(true)
    const [upToefl, setUpToefl] = useState(true)

    const [downIeltsCost, setDownIeltsCost] = useState(true)
    const [upIeltsCost, setUpIeltsCost] = useState(true)
    const [downIelts, setDownIelts] = useState(true)
    const [upIelts, setUpIelts] = useState(true)

    const [downGreCost, setDownGreCost] = useState(true)
    const [upGreCost, setUpGreCost] = useState(true)
    const [downGre, setDownGre] = useState(true)
    const [upGre, setUpGre] = useState(true)


    const [downSatCost, setDownSatCost] = useState(true)
    const [upSatCost, setUpSatCost] = useState(true)
    const [downSat, setDownSat] = useState(true)
    const [upSat, setUpSat] = useState(true)

    const [downGmatCost, setDownGmatCost] = useState(true)
    const [upGmatCost, setUpGmatCost] = useState(true)
    const [downGmat, setDownGmat] = useState(true)
    const [upGmat, setUpGmat] = useState(true)

    const handleToeflCostClick = () => {
        setDownToefl(true)
        setUpToefl(true)
        setDownIeltsCost(true)
        setUpIeltsCost(true)
        setDownIelts(true)
        setUpIelts(true)
        setDownGreCost(true)
        setUpGreCost(true)
        setDownGre(true)
        setUpGre(true)
        setDownSatCost(true)
        setUpSatCost(true)
        setDownSat(true)
        setUpSat(true)
        setDownGmatCost(true)
        setUpGmatCost(true)
        setDownGmat(true)
        setUpGmat(true)

        setDownToeflCost(false)
        setUpToeflCost(false)

        setToeflCost(true)
        setToefl(false)
        setIeltsCost(false)
        setIelts(false)
        setGreCost(false)
        setGre(false)
        setSatCost(false)
        setSat(false)
        setGmatCost(false)
        setGmat(false)
    }
    const handleToeflClick = () => {
        setDownToeflCost(true)
        setUpToeflCost(true)
        setDownIeltsCost(true)
        setUpIeltsCost(true)
        setDownIelts(true)
        setUpIelts(true)
        setDownGreCost(true)
        setUpGreCost(true)
        setDownGre(true)
        setUpGre(true)
        setDownSatCost(true)
        setUpSatCost(true)
        setDownSat(true)
        setUpSat(true)
        setDownGmatCost(true)
        setUpGmatCost(true)
        setDownGmat(true)
        setUpGmat(true)

        setDownToefl(false)
        setUpToefl(false)

        setToeflCost(false)
        setToefl(true)
        setIeltsCost(false)
        setIelts(false)
        setGreCost(false)
        setGre(false)
        setSatCost(false)
        setSat(false)
        setGmatCost(false)
        setGmat(false)
    }
    const handleIeltsCostClick = () => {
        setDownToeflCost(true)
        setUpToeflCost(true)
        setDownToefl(true)
        setUpToefl(true)
        setDownIelts(true)
        setUpIelts(true)
        setDownGreCost(true)
        setUpGreCost(true)
        setDownGre(true)
        setUpGre(true)
        setDownSatCost(true)
        setUpSatCost(true)
        setDownSat(true)
        setUpSat(true)
        setDownGmatCost(true)
        setUpGmatCost(true)
        setDownGmat(true)
        setUpGmat(true)

        setDownIeltsCost(false)
        setUpIeltsCost(false)

        setToeflCost(false)
        setToefl(false)
        setIeltsCost(true)
        setIelts(false)
        setGreCost(false)
        setGre(false)
        setSatCost(false)
        setSat(false)
        setGmatCost(false)
        setGmat(false)
    }

    const handleIeltsClick = () => {
        setDownToeflCost(true)
        setUpToeflCost(true)
        setDownToefl(true)
        setUpToefl(true)
        setDownIeltsCost(true)
        setUpIeltsCost(true)
        setDownGreCost(true)
        setUpGreCost(true)
        setDownGre(true)
        setUpGre(true)
        setDownSatCost(true)
        setUpSatCost(true)
        setDownSat(true)
        setUpSat(true)
        setDownGmatCost(true)
        setUpGmatCost(true)
        setDownGmat(true)
        setUpGmat(true)

        setDownIelts(false)
        setUpIelts(false)

        setToeflCost(false)
        setToefl(false)
        setIeltsCost(false)
        setIelts(true)
        setGreCost(false)
        setGre(false)
        setSatCost(false)
        setSat(false)
        setGmatCost(false)
        setGmat(false)
    }
    const handleGreCostClick = () => {
        setDownToeflCost(true)
        setUpToeflCost(true)
        setDownToefl(true)
        setUpToefl(true)
        setDownIeltsCost(true)
        setUpIeltsCost(true)
        setDownIelts(true)
        setUpIelts(true)
        setDownGre(true)
        setUpGre(true)
        setDownSatCost(true)
        setUpSatCost(true)
        setDownSat(true)
        setUpSat(true)
        setDownGmatCost(true)
        setUpGmatCost(true)
        setDownGmat(true)
        setUpGmat(true)

        setDownGreCost(false)
        setUpGreCost(false)

        setToeflCost(false)
        setToefl(false)
        setIeltsCost(false)
        setIelts(false)
        setGreCost(true)
        setGre(false)
        setSatCost(false)
        setSat(false)
        setGmatCost(false)
        setGmat(false)
    }
    const handleGreClick = () => {
        setDownToeflCost(true)
        setUpToeflCost(true)
        setDownToefl(true)
        setUpToefl(true)
        setDownIeltsCost(true)
        setUpIeltsCost(true)
        setDownIelts(true)
        setUpIelts(true)
        setDownGreCost(true)
        setUpGreCost(true)
        setDownSatCost(true)
        setUpSatCost(true)
        setDownSat(true)
        setUpSat(true)
        setDownGmatCost(true)
        setUpGmatCost(true)
        setDownGmat(true)
        setUpGmat(true)

        setDownGre(false)
        setUpGre(false)

        setToeflCost(false)
        setToefl(false)
        setIeltsCost(false)
        setIelts(false)
        setGreCost(false)
        setGre(true)
        setSatCost(false)
        setSat(false)
        setGmatCost(false)
        setGmat(false)
    }
    const handleSatCostClick = () => {
        setDownToeflCost(true)
        setUpToeflCost(true)
        setDownToefl(true)
        setUpToefl(true)
        setDownIeltsCost(true)
        setUpIeltsCost(true)
        setDownIelts(true)
        setUpIelts(true)
        setDownGreCost(true)
        setUpGreCost(true)
        setDownGre(true)
        setUpGre(true)
        setDownSat(true)
        setUpSat(true)
        setDownGmatCost(true)
        setUpGmatCost(true)
        setDownGmat(true)
        setUpGmat(true)

        setDownSatCost(false)
        setUpSatCost(false)

        setToeflCost(false)
        setToefl(false)
        setIeltsCost(false)
        setIelts(false)
        setGreCost(false)
        setGre(false)
        setSatCost(true)
        setSat(false)
        setGmatCost(false)
        setGmat(false)
    }

    const handleSatClick = () => {
        setDownToeflCost(true)
        setUpToeflCost(true)
        setDownToefl(true)
        setUpToefl(true)
        setDownIeltsCost(true)
        setUpIeltsCost(true)
        setDownIelts(true)
        setUpIelts(true)
        setDownGreCost(true)
        setUpGreCost(true)
        setDownGre(true)
        setUpGre(true)
        setDownSatCost(true)
        setUpSatCost(true)
        setDownGmatCost(true)
        setUpGmatCost(true)
        setDownGmat(true)
        setUpGmat(true)

        setDownSat(false)
        setUpSat(false)

        setToeflCost(false)
        setToefl(false)
        setIeltsCost(false)
        setIelts(false)
        setGreCost(false)
        setGre(false)
        setSatCost(false)
        setSat(true)
        setGmatCost(false)
        setGmat(false)
    }
    const handleGmatCostClick = () => {
        setDownToeflCost(true)
        setUpToeflCost(true)
        setDownToefl(true)
        setUpToefl(true)
        setDownIeltsCost(true)
        setUpIeltsCost(true)
        setDownIelts(true)
        setUpIelts(true)
        setDownGreCost(true)
        setUpGreCost(true)
        setDownGre(true)
        setUpGre(true)
        setDownSatCost(true)
        setUpSatCost(true)
        setDownSat(true)
        setUpSat(true)
        setDownGmat(true)
        setUpGmat(true)

        setDownGmatCost(false)
        setUpGmatCost(false)

        setToeflCost(false)
        setToefl(false)
        setIeltsCost(false)
        setIelts(false)
        setGreCost(false)
        setGre(false)
        setSatCost(false)
        setSat(false)
        setGmatCost(true)
        setGmat(false)
    }

    const handleGmatClick = () => {
        setDownToeflCost(true)
        setUpToeflCost(true)
        setDownToefl(true)
        setUpToefl(true)
        setDownIeltsCost(true)
        setUpIeltsCost(true)
        setDownIelts(true)
        setUpIelts(true)
        setDownGreCost(true)
        setUpGreCost(true)
        setDownGre(true)
        setUpGre(true)
        setDownSatCost(true)
        setUpSatCost(true)
        setDownSat(true)
        setUpSat(true)
        setDownGmatCost(true)
        setUpGmatCost(true)

        setDownGmat(false)
        setUpGmat(false)
        
        setToeflCost(false)
        setToefl(false)
        setIeltsCost(false)
        setIelts(false)
        setGreCost(false)
        setGre(false)
        setSatCost(false)
        setSat(false)
        setGmatCost(false)
        setGmat(true)
    }

    return (
        <div>
            <GeneralHeader1 />
            <div className={faqstyles.upperSection}>
                <div className={faqstyles.faqTopContainer}>
                    <section className={faqstyles.faqImage}></section>
                    <section className={faqstyles.faqText}>
                        <p className={faqstyles.topP}>Frequently <br /> Asked <br /> Questions</p>
                    </section>
                    <div className={faqstyles.faqSideContainer}></div>
                </div>
            </div>
            <p className={faqstyles.faqRes}>Frequently Asked Questions</p>
            <section className={faqstyles.sectionContainer}>
            <main className={faqstyles.mainContainer}>
                <div className={faqstyles.divContainer}>
                    <p className={faqstyles.firstP} onClick={handleToeflCostClick}>TOEFL cost? 
                        <span>
                        <FontAwesomeIcon icon={['fas', 'sort-up']} size="1x" style={{display : upToeflCost ? "none" : "inline"}} />
                        <FontAwesomeIcon icon={['fas', 'sort-down']} size="1x" style={{display : downToeflCost ? "inline" : "none"}} />
                        </span>
                    </p>
                    <p className={toeflCost ? faqstyles.secondPDisplay : faqstyles.secondP}>
                        Test of English as as a foreign language, valid for 2 years consisting of English Language (Reading and Writing). Its total score is 120 marks, its exams can be written on any available day in a week. Its examination cost N100,000, study material (KAPLAN/ETS) cost N20,000 which is optional.
                    </p>
                </div>
                <div className={faqstyles.divContainer}>
                    <p className={faqstyles.firstP} onClick={handleToeflClick}>What are the available dates for the TOEFL test in Nigeria?
                        <span>
                            <FontAwesomeIcon icon={['fas', 'sort-up']} size="1x" style={{display : upToefl ? "none" : "inline"}} />
                            <FontAwesomeIcon icon={['fas', 'sort-down']} size="1x" style={{display : downToefl ? "inline" : "none"}} />
                        </span>
                    </p>
                    <p className={toefl ? faqstyles.secondPDisplay : faqstyles.secondP}>
                        The TOEFL examination/test is taken three times in each month of the year at certified pro-metric centers. There are few TOEFL test locations in Nigeria, and test seats get quickly filled up so test takers are usually advised to register ahead of time in order to secure their preferred test dates. Registering early for your TOEFL exam will ensure that your test scores meet up with the application deadline for your schools.
                    </p>
                </div>
                <div className={faqstyles.divContainer}>
                    <p className={faqstyles.firstP} onClick={handleIeltsCostClick}>IELTS Cost?
                        <span>
                            <FontAwesomeIcon icon={['fas', 'sort-up']} size="1x" style={{display : upIeltsCost ? "none" : "inline"}} />
                            <FontAwesomeIcon icon={['fas', 'sort-down']} size="1x" style={{display : downIeltsCost ? "inline" : "none"}} />
                        </span>
                    </p>
                    <p className={ieltsCost ? faqstyles.secondPDisplay : faqstyles.secondP}>
                        International English Language Testing System, valid for 2 years consisting of English Language (Reading, Listening and Writing). Its total score is 0-9 marks, its exams can be written on any available day in a week. Its examination cost N90,000, study material (KAPLAN/ETS) cost N20,000 which is optional.
                    </p>
                </div>
                <div className={faqstyles.divContainer}>
                    <p className={faqstyles.firstP} onClick={handleIeltsClick}>What are the available dates for the IELTS test in Nigeria?
                        <span>
                            <FontAwesomeIcon icon={['fas', 'sort-up']} size="1x" style={{display : upIelts ? "none" : "inline"}} />
                            <FontAwesomeIcon icon={['fas', 'sort-down']} size="1x" style={{display : downIelts ? "inline" : "none"}} />
                        </span>
                    </p>
                    <p className={ielts ? faqstyles.secondPDisplay : faqstyles.secondP}>
                        The IELTS examination/test is taken three times in each month of the year at certified pro-metric centers. There are few IELTS test locations in Nigeria, and test seats get quickly filled up so test takers are usually advised to register ahead of time in order to secure their preferred test dates. Registering early for your IELTS exam will ensure that your test scores meet up with the application deadline for your schools
                    </p>
                </div>
                <div className={faqstyles.divContainer}>
                    <p className={faqstyles.firstP} onClick={handleGreCostClick}>GRE Cost?
                        <span>
                            <FontAwesomeIcon icon={['fas', 'sort-up']} size="1x" style={{display : upGreCost ? "none" : "inline"}} />
                            <FontAwesomeIcon icon={['fas', 'sort-down']} size="1x" style={{display : downGreCost ? "inline" : "none"}} />
                        </span>
                    </p>
                    <p className={greCost ? faqstyles.secondPDisplay : faqstyles.secondP}>
                        Graduate Record Examination, valid for 3 years consisting of Mathematics (Multiple-Choice, Comparism and Grid-Ins), English Language (Vocabularies and Writing). Its total score is 340 marks, (Mathematics 170 marks, English 170 marks). its exams can be written on any available day in a week. Its examination cost N120,000, study material (KAPLAN/ETS) cost N20,000 which is optional.
                    </p>
                </div>
                <div className={faqstyles.divContainer}>
                    <p className={faqstyles.firstP} onClick={handleGreClick}>What are the available dates for the GRE test in Nigeria?
                        <span>
                            <FontAwesomeIcon icon={['fas', 'sort-up']} size="1x" style={{display : upGre ? "none" : "inline"}} />
                            <FontAwesomeIcon icon={['fas', 'sort-down']} size="1x" style={{display : downGre ? "inline" : "none"}} />
                        </span>
                    </p>
                    <p className={gre ? faqstyles.secondPDisplay : faqstyles.secondP}>
                        The GRE examination/test is taken two (2) - four (4) times in a month on weekends, throughout the year at certified pro-metric centers.
                    </p>
                </div>
                <div className={faqstyles.divContainer}>
                    <p className={faqstyles.firstP} onClick={handleSatCostClick}>SAT Cost?
                        <span>
                            <FontAwesomeIcon icon={['fas', 'sort-up']} size="1x" style={{display : upSatCost ? "none" : "inline"}} />
                            <FontAwesomeIcon icon={['fas', 'sort-down']} size="1x" style={{display : downSatCost ? "inline" : "none"}} />
                        </span>
                    </p>
                    <p className={satCost ? faqstyles.secondPDisplay : faqstyles.secondP}>
                        Scholastic Aptitude Test is test for admission for First Degree in USA Universities, valid for 2 years consisting of Mathematics (Calculator and no calculator), English Language (Reading and Writing). Its total score is 1600 marks (Mathematics 800 marks, English 800 marks). its exams can be written on any available day in a week. Its examination cost N75,000, study material (KAPLAN/ETS) cost N20,000 which is optional.
                    </p>
                </div>
                <div className={faqstyles.divContainer}>
                    <p className={faqstyles.firstP} onClick={handleSatClick}>What are the available dates for the SAT test in Nigeria?
                        <span>
                            <FontAwesomeIcon icon={['fas', 'sort-up']} size="1x" style={{display : upSat ? "none" : "inline"}} />
                            <FontAwesomeIcon icon={['fas', 'sort-down']} size="1x" style={{display : downSat ? "inline" : "none"}} />
                        </span>
                    </p>
                    <p className={sat ? faqstyles.secondPDisplay : faqstyles.secondP}>
                        The test/exam is administered by the College board (ETS) in conjunction with GIEVA in Nigeria. The exam is arranged about seven(7) to eight(8) times in a year and it is just one examination per month. Students are advised to register and plan ahead for their examinations as registration closes several weeks before the exam date
                    </p>
                </div>
                <div className={faqstyles.divContainer}>
                    <p className={faqstyles.firstP} onClick={handleGmatCostClick}>GMAT Cost?
                        <span>
                            <FontAwesomeIcon icon={['fas', 'sort-up']} size="1x" style={{display : upGmatCost ? "none" : "inline"}} />
                            <FontAwesomeIcon icon={['fas', 'sort-down']} size="1x" style={{display : downGmatCost ? "inline" : "none"}} />
                        </span>
                    </p>
                    <p className={gmatCost ? faqstyles.secondPDisplay : faqstyles.secondP}>
                        Graduate Management Assessment Test, valid for 3 years consisting ofMathematics (Problem solving and Data Sufficient), English Language (Critical reading, Writing and Integrated Reasoning). Its total score is 800 marks (Mathematics 400 marks, English 400 marks). its exams can be written on any available day in a week. Its examination cost N120,000, study material (KAPLAN/ETS) cost N20,000 which is optional.
                    </p>
                </div>
                <div className={faqstyles.divContainer}>
                    <p className={faqstyles.firstP} onClick={handleGmatClick}>What are the available dates for the SAT test in Nigeria?
                        <span>
                            <FontAwesomeIcon icon={['fas', 'sort-up']} size="1x" style={{display : upGmat ? "none" : "inline"}} />
                            <FontAwesomeIcon icon={['fas', 'sort-down']} size="1x" style={{display : downGmat ? "inline" : "none"}} />
                        </span>
                    </p>
                    <p className={gmat ? faqstyles.secondPDisplay : faqstyles.secondP}>
                        The GMAT examination/test is taken on every Wednesdays and Thursdays of the week in Abuja while in Lagos, it is taken everyday of the week (Mondays - Saturdays) throughout the year at certified prometric center
                    </p>
                </div>
            </main>
            </section>
            <Footer />
        </div>
    )
}

export default FAQs
