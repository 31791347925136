import React, {useState} from 'react'
import Footer from './Footer'
import GeneralHeader1 from './GeneralHeader1'
import Guaranteed from './Guaranteed'
import Progress from './Progress'
import ProgressReal from './ProgressReal'
import styles from './AboutHome.module.css'
import Clients from './Clients'
import Enquiry from './Enquiry'
import { Link } from 'react-router-dom'
import './gallery.css'
import Slider from 'react-slick'
import {ReactComponent as Arrow} from './images/arrows.svg'

function About() {
    const [onExam, setOnExam] = useState(true)
    const handleExamHover = () => {
        setOnExam(false)
        setOnStudy(true)
        setOnTourism(true)
        setOnAdult(true)
        setOnLanguage(true)
    }
    const handleExamHoverOut = () => {
        setOnExam(true)
        setOnStudy(true)
        setOnTourism(true)
        setOnAdult(true)
        setOnLanguage(true)
    }

    const [onStudy, setOnStudy] = useState(true)
    const handleStudyHover = () => {
        setOnStudy(false)
        setOnExam(true)
        setOnTourism(true)
        setOnAdult(true)
        setOnLanguage(true)
    }
    const handleStudyHoverOut = () => {
        setOnStudy(true)
        setOnExam(true)
        setOnTourism(true)
        setOnAdult(true)
        setOnLanguage(true)
    }

    const [onTourism, setOnTourism] = useState(true)
    const handleTourismHover = () => {
        setOnTourism(false)
        setOnExam(true)
        setOnStudy(true)
        setOnAdult(true)
        setOnLanguage(true)
    }
    const handleTourismHoverOut = () => {
        setOnTourism(true)
        setOnExam(true)
        setOnStudy(true)
        setOnAdult(true)
        setOnLanguage(true)
    }
    const [onAdult, setOnAdult] = useState(true)
    const handleAdultHover = () => {
        setOnAdult(false)
        setOnExam(true)
        setOnStudy(true)
        setOnTourism(true)
        setOnLanguage(true)
    }
    const handleAdultHoverOut = () => {
        setOnAdult(true)
        setOnExam(true)
        setOnStudy(true)
        setOnTourism(true)
        setOnAdult(true)
    }
    const [onLanguage, setOnLanguage] = useState(true)
    const handleLanguageHover = () => {
        setOnLanguage(false)
        setOnExam(true)
        setOnStudy(true)
        setOnTourism(true)
        setOnAdult(true)

    }
    const handleLanguageHoverOut = () => {
        setOnLanguage(true)
    }


    const slide = {
        dots: true,
        arrows: false,
        centerPadding: '40px',
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        pauseOnDotsHover:true,
        pauseOnFocus:true,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 600,
              settings: {
                arrows: false,
                centerMode: false,
                centerPadding: '5px',
                slidesToShow: 1,
                slidesToScroll: 1
                
              }
            },
          ]

    }

    return (
        <div>
            <GeneralHeader1 />
            <section className={styles.firstSection}>
                <section className={`${styles.firstSectionDiv}  ${styles.aboutImage} ${styles.firstSectionDivAbout}`}>
                <div className={styles.firstASecDiv}>
                    <h1 className={`${styles.firstSectionDivP} ${styles.firstASecDivH1}`}>About FNG</h1>
                    <p className={styles.firstASecDivP}>First New Generation Citadel founded by Dr. Michelle B. Ukoh, is on a mission to provide an educational ecosystem, which will enhance education empowerment for competition in the global market. For over fifteen (15) years, we have proven to be the number one scholarship and admission center in Nigeria, providing affordable, safe, secure, education that gurantees progress, boost test scores and, we provide international admission and scholarships globally.</p>
                </div>
                </section>
            </section>
            <main>
                <section>
                    <div style={{display:"flex", justifyContent:"center", alignContent:"center"}}>
                    <div className={styles.AboutStatements}>
                    <Slider {...slide}>
                    <div className={styles.AStatementLi}>
                            <h1 className={styles.authTFirstSideText}>Motto</h1>
                            <p className={styles.authTFirstSideTextP}>Unlimited possibilities</p>
                        </div>
                        <div className={styles.AStatementLi}>
                            <p className={styles.authTFirstSideText}>Our Vision</p>
                            <p className={styles.authTFirstSideTextP}>To establish, manage and adminster a model private study center of excellence</p>
                            </div>
                        <div className={styles.AStatementLi}>
                            <p className={styles.authTFirstSideText}>Our Mission</p>
                            <p className={styles.authTFirstSideTextP}>To provide “One Stop Service” which will enhance education empowerment for competition in the global market</p>
                        </div>
                    </Slider>
                    </div>
                    </div>
                    {/* <Nonye /> */}

                    <div className={styles.ATeamUlContainer}>
                        <ul className={styles.ATeamUl}>
                            <h1 className={`${styles.authTFirstSideText} ${styles.TeamH1}`}>Our Team</h1>
                            <li className={styles.ATeamUlLi}>
                                <div className={styles.TeamFigure}>
                                    <div className={`${styles.ceo} ${styles.teamPhoto}`}></div>
                                    <div className={styles.TeamFigureCap}>
                                        <p className={styles.TeamP}>Michelle Bacote Ukoh</p>
                                        <p className={styles.TeamP2}>CEO</p>
                                    </div>
                                </div>
                            </li>
                            <li className={styles.ATeamUlLi}>
                                <div className={styles.TeamFigure}>
                                    <div className={`${styles.nonye} ${styles.teamPhoto}`}></div>
                                    <div className={styles.TeamFigureCap}>
                                        <p className={styles.TeamP}>Dr. Barr. Nonye Ukoh</p>
                                        <p className={styles.TeamP2}>COO</p>
                                    </div>
                                </div>
                            </li>
                            <li className={styles.ATeamUlLi}>
                                <div className={styles.TeamFigure}>
                                <div className={`${styles.amazu} ${styles.teamPhoto}`}></div>
                                    <div className={styles.TeamFigureCap}>
                                        <p className={styles.TeamP}>Amazu Ukoh</p>
                                        <p className={styles.TeamP2}>Chaplin/ Marketing Specialtist </p>
                                    </div>
                                </div>
                                </li>
                            <li className={styles.ATeamUlLi}>
                                <div className={styles.TeamFigure}>
                                <div className={`${styles.beamon} ${styles.teamPhoto}`}></div>
                                    <div className={styles.TeamFigureCap}>
                                        <p className={styles.TeamP}>Robin Beaman</p>
                                        <p className={styles.TeamP2}>CMO</p>
                                    </div>
                                </div>
                            </li>
                            <li className={styles.ATeamUlLi}>
                                <div className={styles.TeamFigure}>
                                <div className={`${styles.alex} ${styles.teamPhoto}`}></div>
                                    <div className={styles.TeamFigureCap}>
                                        <p className={styles.TeamP}>Alexi Grave</p>
                                        <p className={styles.TeamP2}>International Consultant</p>
                                    </div>
                                </div>
                            </li>
                            <li className={styles.ATeamUlLi}>
                                <div className={styles.TeamFigure}>
                                <div className={`${styles.oluwaseyi} ${styles.teamPhoto}`}></div>
                                    <div className={styles.TeamFigureCap}>
                                        <p className={styles.TeamP}>Adebayo Oluwaseyi</p>
                                        <p className={styles.TeamP2}>Master Teacher</p>
                                    </div>
                                </div>
                            </li>
                            <li className={styles.ATeamUlLi}>
                                <div className={styles.TeamFigure}>
                                <div className={`${styles.jide} ${styles.teamPhoto}`}></div>
                                    <div className={styles.TeamFigureCap}>
                                        <p className={styles.TeamP}>Olajide Adedokun</p>
                                        <p className={styles.TeamP2}>Master Teacher</p>
                                    </div>
                                </div>
                            </li>
                            <li className={styles.ATeamUlLi}>
                                <div className={styles.TeamFigure}>
                                <div className={`${styles.lawrence} ${styles.teamPhoto}`}></div>
                                    <div className={styles.TeamFigureCap}>
                                        <p className={styles.TeamP}>Lawrence Ukute</p>
                                        <p className={styles.TeamP2}>Master Teacher</p>
                                    </div>
                                </div>
                            </li>
                            <li className={styles.ATeamUlLi}>
                                <div className={styles.TeamFigure}>
                                <div className={`${styles.amazu} ${styles.teamPhoto}`}></div>
                                    <div className={styles.TeamFigureCap}>
                                        <p className={styles.TeamP}>Amazu Ukoh</p>
                                        <p className={styles.TeamP2}>Master Teacher</p>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                    <div className={styles.DoUlContainer}>
                        <h1 className={`${styles.authTFirstSideText} ${styles.TeamH1}`}>What we do</h1>
                        <ul className={styles.DoUl}>
                            <li className={`${styles.DoUlLi} ${styles.DoUlLi2}`} style={{padding:"0 10px"}} onMouseMove={handleExamHover} onMouseOut={handleExamHoverOut}>
                                <Link to="/exam" style={{textDecoration:"none"}} className={styles.DoUlLi2}>                    
                                    <p className={`${styles.DoP} ${styles.dopArrow}`}>Examination Preparations <span className={onExam ? styles.spanArrow : styles.spanArrowDisplay}><Arrow /></span></p>
                                    <p className={styles.DoP2}>No matter where you’re at in your education or career, FNG will help you reach the next level with confidence. Choose a study option that fits your life: in-classroom, online, private tutoring or self-paced with give you an edge.</p>
                                </Link>
                            </li>
                            <li className={`${styles.DoUlLi} ${styles.DoUlLi2}`} style={{padding:"0 10px"}} onMouseMove={handleStudyHover} onMouseOut={handleStudyHoverOut}>
                                <Link to="/study/abroad" style={{textDecoration:"none"}} className={styles.DoUlLi2}>                   
                                    <p className={`${styles.DoP} ${styles.dopArrow}`}>Study Abroad <span className={onStudy ? styles.spanArrow : styles.spanArrowDisplay}><Arrow /></span></p>
                                    <p className={styles.DoP2}><b>First New Generation Citadel Tracklane</b> is your fast path to academic excellence. Every year, students from Nigeria enter well thought of programs at our hand-selected partner universities in United States, Turkey, Canada and Dubai. </p>
                                </Link> 
                            </li>
                            <li className={`${styles.DoUlLi} ${styles.DoUlLi2}`} style={{padding:"0 10px"}} onMouseMove={handleTourismHover} onMouseOut={handleTourismHoverOut}>
                                <Link to="/error" style={{textDecoration:"none"}} className={styles.DoUlLi2}>                   
                                    <p className={`${styles.DoP} ${styles.dopArrow}`}>Tourism <span className={onTourism ? styles.spanArrow : styles.spanArrowDisplay}><Arrow /></span></p>
                                    <p className={`${styles.DoP2} ${styles.DoPCol1}`}>No matter where you’re at in your education or career, FNG will help you reach the next level with confidence. Choose a study option that fits your life: in-classroom, online, private tutoring or self-paced with give you an edge.</p>
                                </Link>
                            </li>
                            <li className={`${styles.DoUlLi} ${styles.DoUlLi2}`} style={{padding:"0 10px"}} onMouseMove={handleLanguageHover} onMouseOut={handleLanguageHoverOut}>
                                <Link to="/error" style={{textDecoration:"none"}} className={styles.DoUlLi2}>                   
                                    <p className={`${styles.DoP} ${styles.dopArrow} ${styles.DoPCol1}`}>Learn a new language <span className={onLanguage ? styles.spanArrow : styles.spanArrowDisplay}><Arrow /></span></p>
                                    <p className={`${styles.DoP2} ${styles.DoPCol1}`}>No matter where you’re at in your education or career, FNG will help you reach the next level with confidence. Choose a study option that fits your life: in-classroom, online, private tutoring or self-paced with give you an edge.</p>
                                </Link>
                            </li>
                            <li className={`${styles.DoUlLi} ${styles.DoUlLi2}`} style={{padding:"0 10px"}} onMouseMove={handleAdultHover} onMouseOut={handleAdultHoverOut}>
                                <Link to="/error" style={{textDecoration:"none"}} className={styles.DoUlLi2}>                   
                                    <p className={`${styles.DoP} ${styles.dopArrow} ${styles.DoPCol1}`}>Adult Literacy <span className={onAdult ? styles.spanArrow : styles.spanArrowDisplay}><Arrow /></span></p>
                                    <p className={`${styles.DoP2} ${styles.DoPCol1}`}>No matter where you’re at in your education or career, FNG will help you reach the next level with confidence. Choose a study option that fits your life: in-classroom, online, private tutoring or self-paced with give you an edge.</p>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.DoUlContainer}>
                    <Link to="/gallery" style={{color:"#313059", textDecoration:"none"}}>

                    <h1 className={`${styles.authTFirstSideText} ${styles.TeamH1}`}>Gallery</h1>
                        <div className={`${styles.DoUlLi} ${styles.GalleryUl}`}>
                            <Slider {...slide}>
                            <div className={styles.gal}>
                                <figure className={styles.GalleryUlLi}></figure>
                            </div>
                            <div className={styles.gal}>
                                <figure className={styles.GalleryUlLi}></figure>
                            </div>
                            <div className={styles.gal}>
                                <figure className={styles.GalleryUlLi}></figure>
                            </div>
                            <div className={styles.gal}>
                                <figure className={styles.GalleryUlLi}></figure>
                            </div>
                            <div className={styles.gal}>
                                <figure className={styles.GalleryUlLi}></figure>
                            </div>
                            </Slider>
                        </div>
                        </Link>
                    </div>
                </section>
                <Clients />
            </main>
            <Guaranteed />
            <Progress />
            <ProgressReal />
            <Enquiry />
            <Footer />
        </div>
    )
}

export default About
