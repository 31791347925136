import React from 'react'
import AuthSideT from './AuthSideT'
import GeneralHeader1 from './GeneralHeader1'
import tutorstyle from './TutorAuth.module.css'
import { Link } from 'react-router-dom'
function AuthSideSche() {
    return (
        <div>
            <GeneralHeader1 />
            <main className={tutorstyle.BigContainer}>
                <div className={tutorstyle.Container}>
                <AuthSideT />
                <section className={tutorstyle.secondSide}>
                    <h4 className={tutorstyle.secondSideH4}>Scheduling</h4>
                    <form action="" className={tutorstyle.form}>
                        <h5 className={tutorstyle.formH5}>Scheduling</h5>
                        <div className={tutorstyle.formDiv}>
                            <p className={tutorstyle.formList}>FNG is only interested in tutors who would be available to tutor 5 or more hours per day<span>*</span></p>
                            <select name="" id="" className={`${tutorstyle.input} ${tutorstyle.listmargin}`}>Select 
                                <option value=""></option>
                            </select>
                            <p className={`${tutorstyle.formList} ${tutorstyle.listmargin}`}>FNG’s service hours are 7 days, 24 hours a days. You don not have to be available every day or hours listed. Choose your working week days.</p>
                            <div className={tutorstyle.divBtn}>
                                <input type="radio" name="" id="" className={`${tutorstyle.radBtn} ${tutorstyle.radBtn3}`} />
                                <label htmlFor="" className={tutorstyle.labelBtn2}>Monday</label>
                                <input type="radio" name="" id="" className={`${tutorstyle.radBtn} ${tutorstyle.radBtn3}`} />
                                <label htmlFor="" className={tutorstyle.labelBtn2}>Tuesday</label>
                                <input type="radio" name="" id="" className={`${tutorstyle.radBtn} ${tutorstyle.radBtn3}`} />
                                <label htmlFor="" className={tutorstyle.labelBtn2}>Wednesday</label>
                                <br />
                                <input type="radio" name="" id="" className={`${tutorstyle.radBtn} ${tutorstyle.radBtn3}`} />
                                <label htmlFor="" className={tutorstyle.labelBtn2}>Thursday</label>
                                <input type="radio" name="" id="" className={`${tutorstyle.radBtn} ${tutorstyle.radBtn3}`} />
                                <label htmlFor="" className={tutorstyle.labelBtn2}>Friday</label>
                            </div>
                            <p className={tutorstyle.FormList}>Will you work weekends?</p>
                            <input type="radio" name="" id="" className={`${tutorstyle.radBtn} ${tutorstyle.radBtn3}`}  />
                            <label htmlFor="" className={tutorstyle.labelBtn2}>Yes</label>
                            <input type="radio" name="" id="" className={`${tutorstyle.radBtn} ${tutorstyle.radBtn3}`}  />
                            <label htmlFor="" className={tutorstyle.labelBtn2}>No</label>
                            <p className={`${tutorstyle.FormList} ${tutorstyle.listmargin}`}>Available hours for weekends</p>
                            <select name="" id="" className={`${tutorstyle.input} ${tutorstyle.listmargin}`}>Select 
                                <option value=""></option>
                            </select>
                        </div>
                        <button className={`${tutorstyle.btn} ${tutorstyle.btnFirstChild}`}>
                            <Link to="/tutor/workexperience" style={{color:"#fff", textDecoration:"none"}}>BACK</Link>
                        </button>
                        <button className={`${tutorstyle.btn} ${tutorstyle.btnLastChild}`}>
                        <Link to="/tutor/subjectspecialty" style={{color:"#fff", textDecoration:"none"}}>NEXT</Link>
                        </button>
                    </form>
                </section>
                </div>
            </main>
        </div>
    )
}

export default AuthSideSche
